import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePrintContentLabels } from "../../../../utils/printer-functions/printContentLabels";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import {
  changeTabValue,
  deleteBoxFromPalletV2,
  removeItemFromPalletBoxV2,
  scanItemToPalletBoxV2,
  toggleBoxDetailsModal,
  toggleCurrentBox,
} from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import {
  AddBox,
  Cancel,
  Delete,
  FilterCenterFocus,
  HourglassBottom,
  Print,
  QrCodeScanner,
  ViewList,
} from "@mui/icons-material";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";
import GeneralModalV3 from "../../../general/GeneralModalV3";
import PackProcessItemDisplay from "./PackProcessItemDisplay";
import PackProcessAllScansView from "./PackProcessAllScansView";

const BoxDetailsInput = ({ box }) => {
  const [itemInput, setItemInput] = useState("");
  const [quantity, setQuantity] = useState(1);

  const itemInputRef = useRef(null);
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.user);
  const { firstpacktime, _id, packdetails, palletnumber, tabValue } =
    useSelector((state) => state.packProcessV2);

  useEffect(() => {
    handleItemRefFocus();
  }, [box.boxpackedamount, tabValue]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      scanItemToPalletBoxV2({
        _id,
        item: itemInput,
        quantity,
        boxId: box._id,
        firstpacktime,
        username,
        boxNumber: box.boxId,
        palletId: packdetails[palletnumber]?.palletId,
      })
    );
    setItemInput("");
    setQuantity(1);
  };

  //handles input focus (hides keyboard when focused)
  const handleItemRefFocus = () => {
    if (itemInputRef.current !== null) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  };

  return (
    <Grid container component="form" onSubmit={handleSubmit}>
      <Grid item xs={3}>
        <TextField
          fullWidth
          required
          inputMode="numeric"
          type="number"
          size="small"
          label="Qty"
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          inputProps={{ min: 1, autoComplete: "off" }}
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          size="small"
          label="Scan Item"
          placeholder={`Enter Item in Box ${box.boxId}`}
          value={itemInput}
          onChange={(event) => setItemInput(event.target.value.toUpperCase())}
          inputRef={itemInputRef}
          inputProps={{ autoComplete: "off" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <QrCodeScanner />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const PackProcessBoxDetailsModal = () => {
  const {
    packdetails,
    palletnumber,
    isBoxModalOpen,
    _id,
    totalitemstopack,
    totalpacked,
    tabValue,
    scans,
    createdfrom,
    ponumber,
  } = useSelector((state) => state.packProcessV2);
  const { currentPrinter } = useSelector((state) => state.printers);
  const { openAlert } = useGeneralAlert();
  const dispatch = useDispatch();

  const box = packdetails[palletnumber]?.boxes?.find(
    (boxObj) => boxObj.boxId === isBoxModalOpen
  );
  //checks if box is the most reently created box
  const isLastBox =
    packdetails[palletnumber]?.boxes[packdetails[palletnumber].boxes.length - 1]
      ?.boxId === box.boxId;

  if (!box) {
    return (
      <Container>
        <FlexWrapper>
          <IconButton onClick={() => dispatch(toggleCurrentBox(null))}>
            <Cancel />
          </IconButton>
        </FlexWrapper>
        <Typography variant="h4" textAlign="center">
          Box Not Found
        </Typography>
      </Container>
    );
  }

  const handleDeleteBoxItem = ({
    item,
    itemId,
    removeQty,
    palletId,
    boxId,
    boxNumber,
    palletItemId,
  }) => {
    dispatch(
      removeItemFromPalletBoxV2({
        _id,
        item,
        itemId,
        removeQty,
        palletId,
        boxId,
        boxNumber,
        palletItemId,
      })
    );
  };

  const handleClearPallet = async () => {
    const { items } = box;
    for (let i = 0; i < items.length; i++) {
      const itemObj = items[i];

      await dispatch(
        removeItemFromPalletBoxV2({
          _id,
          item: itemObj.item.item,
          itemId: itemObj.item._id,
          removeQty: parseInt(itemObj.palletpackedamount),
          palletId: parseInt(packdetails[palletnumber]?.palletId),
          boxId: box._id,
          boxNumber: box.boxId,
          palletItemId: itemObj._id,
          last: i === items.length - 1,
          type: "clear-box",
        })
      ).unwrap();
    }
  };

  const handleDeleteBox = ({ boxId, palletId, boxNumber }) => {
    dispatch(deleteBoxFromPalletV2({ _id, palletId, boxId, boxNumber }));
    dispatch(toggleCurrentBox(null));
    dispatch(toggleBoxDetailsModal(null));
  };

  const printContentLabels = async () => {
    const templateHeader = `^XA^MMT^PW812^LL1218^LS0
    ^FX
    ^FT0,85^FB800,1,0,C,0^A0N,75^FDCONTENTS^FS
    ^FT0,220^FB800,1,0,C,0^A0N,150^FD${createdfrom}^FS
    ^FT0,300^FB800,1,0,C,0^A0N,75^FDPO#: ${ponumber}^FS
    ^FT10,375^FB375,1,0,L,0^A0N,50^FDPALLET: ${
      packdetails[palletnumber]?.palletId < 9
        ? `0${packdetails[palletnumber]?.palletId}`
        : packdetails[palletnumber]?.palletId
    }^FS
    ^FT375,375^FB375,1,0,R,0^A0N,50^FDBOX: ${
      box.boxId < 9 ? `0${box.boxId}` : box.boxId
    }^FS
    ^FT0,400^GB820,1,4,B^FS
    ^FT0,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT125,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FT425,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT550,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FX`;
    openAlert({
      type: "success",
      message: "Printing Content Labels...",
      duration: 2000,
    });

    try {
      await handlePrintContentLabels({
        templateHeader,
        currentPrinter,
        type: "box",
        startingArr: box.items,
        title: `Print Content Labels for Box ${box.boxId} Pallet ${packdetails[palletnumber]?.palletId}`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error?.response?.data?.msg || error.message,
        duration: 5000,
      });
    }
  };

  if (!isBoxModalOpen) {
    return null;
  }

  return (
    <Dialog fullScreen open={true} disableEscapeKeyDown>
      <Container maxWidth="md">
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <IconButton
            onClick={() => {
              dispatch(toggleCurrentBox(null));
              dispatch(toggleBoxDetailsModal(null));
              dispatch(changeTabValue(0));
            }}
          >
            <Cancel />
          </IconButton>
          <FlexWrapper alignItems="center" gap={1}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                dispatch(
                  toggleBoxDetailsModal({
                    type: "edit",
                    box,
                  })
                );
                dispatch(toggleCurrentBox(null));
              }}
            >
              Edit Box
            </Button>
            {box.items?.length === 0 && isLastBox && tabValue !== 3 ? (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() =>
                  handleDeleteBox({
                    _id,
                    palletId: packdetails[palletnumber]?.palletId,
                    boxId: box._id,
                    boxNumber: box.boxId,
                  })
                }
              >
                Delete Box
              </Button>
            ) : null}
            {box.items?.length !== 0 && tabValue !== 3 ? (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleClearPallet}
              >
                Clear Box
              </Button>
            ) : null}
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="space-between" alignItems="baseline">
          <FlexWrapper alignItems="center" gap={1}>
            <Typography py={1} variant="h5" fontWeight="bold">
              Pallet {packdetails[palletnumber]?.palletId} Box {box.boxId}
            </Typography>
            {Boolean(box.items.length) && (
              <IconButton size="small" onClick={printContentLabels}>
                <Print color="primary" fontSize="small" />
              </IconButton>
            )}
          </FlexWrapper>
          <Typography>Total Qty: {box.boxpackedamount}</Typography>
        </FlexWrapper>

        {tabValue !== 3 ? (
          <>
            <BoxDetailsInput box={box} />

            <Box>
              <PackProcessItemDisplay
                type="box"
                tabValue={tabValue}
                heightOffset={185}
                deleteItemModal={(itemObj) => (
                  <GeneralModalV3
                    openComponent={
                      <IconButton size="small">
                        <Delete color="error" />
                      </IconButton>
                    }
                  >
                    {(handleClose) => (
                      <Box>
                        <Typography
                          py={1}
                          variant="h5"
                          textAlign="center"
                          fontWeight="bold"
                        >
                          Delete {itemObj.item.item}?
                        </Typography>
                        <Divider />
                        <Box>
                          <Typography py={1} variant="h6" textAlign="center">
                            You will delete a quantity of{" "}
                            <strong>{itemObj.packed}</strong> from{" "}
                            <strong>
                              {" "}
                              Pallet {
                                packdetails[palletnumber]?.palletId
                              } Box {box.boxId}
                            </strong>
                            . Action cannot be undone.
                          </Typography>
                        </Box>
                        <Divider />
                        <FlexWrapper justifyContent="flex-end" p="10px">
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              handleClose();
                              handleDeleteBoxItem({
                                item: itemObj.item.item,
                                itemId: itemObj.item._id,
                                removeQty: parseInt(itemObj.packed),
                                palletId: parseInt(
                                  packdetails[palletnumber]?.palletId
                                ),
                                boxId: box._id,
                                boxNumber: box.boxId,
                                palletItemId: itemObj._id,
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </FlexWrapper>
                      </Box>
                    )}
                  </GeneralModalV3>
                )}
              />
            </Box>
          </>
        ) : (
          <PackProcessAllScansView heightOffset="250px" />
        )}

        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={tabValue}
            onChange={(event, newValue) => {
              dispatch(changeTabValue(newValue));
            }}
          >
            <BottomNavigationAction
              label={`Box ${box.boxId}`}
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={box.boxpackedamount}
                  color="success"
                >
                  <AddBox />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Remaining"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={totalitemstopack - totalpacked}
                  color="error"
                >
                  <HourglassBottom />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="All items"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={totalitemstopack}
                  color="primary"
                >
                  <ViewList />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="All Scans"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={scans.length}
                  color="primary"
                >
                  <FilterCenterFocus />
                </Badge>
              }
            />
          </BottomNavigation>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default PackProcessBoxDetailsModal;
