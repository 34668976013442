import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Add,
  ArrowDropDown,
  CheckCircle,
  DashboardCustomize,
  ExitToApp,
  QrCodeScanner,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  exitPackProcessV2,
  scanItemToPalletV2,
  changePalletNumber,
  completePackV2,
  toggleBoxDetailsModal,
} from "../../../../redux/features/pack-process-v2/packProcessV2Slice";

import FlexWrapper from "../../../FlexWrapper";
import PackProcessPalletModal from "./PackProcessPalletModal";
import PackProcessSearchItem from "./PackProcessSearchItem";
import axios from "../../../../axios/axios.config";

const PackProcessHeader = () => {
  const {
    packdetails,
    palletnumber,
    _id,
    totalpacked,
    totalitemstopack,
    itemfulfillments,
    asndetails,
    createdfrom,
    firstpacktime,
    tabValue,
    packflow,
  } = useSelector((state) => state.packProcessV2);

  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const itemInputRef = useRef(null);
  const { user, navigate } = useOutletContext();

  const [search, setSearch] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [isPalletModalOpen, setisPalletModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useEffect(() => {
    if (itemInputRef?.current) {
      handleItemRefFocus();
    }
  }, [tabValue, palletnumber, isPalletModalOpen, isSearchModalOpen]);
  //exits pack process
  const handleExit = () => {
    dispatch(exitPackProcessV2());
    navigate(
      `/portal/${user.currentDepartment?.toLowerCase()}/pack-process-v2`,
      { replace: true }
    );
    clearPackLockedBy();
  };

  const clearPackLockedBy = async () => {
    const res = await axios.patch(`pack-process-v2/clear-packlockedby/${_id}`);
  };

  //handles scanning items
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      scanItemToPalletV2({
        _id,
        item: search,
        quantity: parseInt(quantity),
        palletId: packdetails[palletnumber]?.palletId,
        firstpacktime,
        username: user.username,
      })
    );
    //reset fields
    setSearch("");
    setQuantity(1);
  };

  //closes the menu
  const handleClose = () => {
    setOpen(false);
  };
  //called in redux to complete the pack
  const handleCompletePack = async () => {
    try {
      const fulfillments = Object.keys(itemfulfillments);
      const fulfillmentTimeObjects = {};

      // Prepare an array of promises for all operations
      const promises = fulfillments.map(async (fulfillment) => {
        fulfillmentTimeObjects[fulfillment] = {
          starttime: "",
          endtime: "",
          packedby: "",
        };
        const fulfillmentinternalid = parseInt(itemfulfillments[fulfillment]);

        const { fulfillmentBreakdown } = await getFulfillmentPackageDetails(
          fulfillmentinternalid,
          fulfillmentTimeObjects[fulfillment]
        );

        // Now perform the update
        const updatePromise = axios.patch(
          `netsuite/pack-process/update/fulfillment/${fulfillmentinternalid}`,
          {
            memo: `Packed By: ${fulfillmentTimeObjects[fulfillment].packedby}`,
            shipStatus: "B",
            custbodysps_packagedetails: fulfillmentBreakdown,
            custbody_packing_start_time:
              fulfillmentTimeObjects[fulfillment].starttime || null,
            custbody_packing_end_time:
              fulfillmentTimeObjects[fulfillment].endtime || null,
            custbody_ait_transaction_packedby:
              fulfillmentTimeObjects[fulfillment].packedby || "",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Wait for the update to complete
        return updatePromise;
      });

      //Wait for all promises to resolve in parallel
      const responses = await Promise.all(promises);

      // Mark as packed in Mongo
      await axios.patch(`pack-process-v2/complete-pack/${_id}`, {
        packedby: user.username,
        endtime: new Date(),
        fulfillmentTimeObjects,
      });

      handleExit();
    } catch (error) {
      await handleLogPackError(error);
      throw error;
    }
  };
  //gets fulfillment breakdowns
  const getFulfillmentPackageDetails = async (
    fulfillmentinternalid,
    fulfillmentTimeObj
  ) => {
    try {
      const fulfillmentPackDetails = []; //final array that will be joined into the breakdown string
      //if asndetails then call the api to create package contents for all fulfilment items at once
      const nsData = {
        route: "createpackagecontents",
        ifulid: fulfillmentinternalid,
        packages: [],
      };

      //map through packdetails to fill out nsData and item details
      packdetails.map(async (pallet) => {
        const palletStorage = {};
        let hasItems = false;
        let boxItems = [];

        // account for box items
        if (pallet.boxes?.length) {
          pallet.boxes.forEach((boxObj) => {
            boxItems = [...boxItems, ...boxObj.items];
          });
        }
        //create combined array of items and box items
        const combinedItems = [...pallet.items, ...boxItems];

        // Process items and update the fulfillment time object
        combinedItems.forEach(
          ({ item, palletpackedamount, timepacked, packedby }) => {
            if (parseInt(item.internalid) === fulfillmentinternalid) {
              hasItems = true;

              if (!palletStorage[item.item]) {
                palletStorage[item.item] = {
                  packed: 0,
                  orderline: parseInt(item.orderline),
                  internalid: parseInt(item.iteminternalid),
                };
              }
              palletStorage[item.item].packed += parseInt(palletpackedamount);

              // Update start and end times
              const timePacked = new Date(timepacked);
              if (
                !fulfillmentTimeObj.starttime ||
                new Date(fulfillmentTimeObj.starttime) > timePacked
              ) {
                fulfillmentTimeObj.starttime = timepacked;

                //log packed by
                if (!fulfillmentTimeObj.packedby) {
                  fulfillmentTimeObj.packedby = packedby;
                }
              }

              if (
                !fulfillmentTimeObj.endtime ||
                new Date(fulfillmentTimeObj.endtime) < timePacked
              ) {
                fulfillmentTimeObj.endtime = timepacked;
              }
            }
          }
        );

        if (hasItems) {
          const detailsText = [
            `Pallet #: ${pallet.palletId}\nDimensions: ${pallet.dimensions}\nWeight: ${pallet.weight} LBS\n-------------------`,
          ];
          const packagelines = [];
          //create item details text and add packagelines
          Object.entries(palletStorage).map(
            ([palletItem, { packed, orderline, internalid }]) => {
              detailsText.push(`${palletItem} x ${packed}`);
              //package lines will be needed if asndetails is true
              packagelines.push({
                linenumber: orderline,
                lineitem: internalid,
                lineqty: packed,
              });
            }
          );

          nsData.packages.push({
            packagenum: pallet.palletId,
            packagedesc: "",
            packagedimensions: pallet.dimensions,
            packageweight: pallet.weight,
            packageucc: "",
            packagetracking: "",
            packagelines,
          });

          fulfillmentPackDetails.push(detailsText.join("\n"));
        }
      });

      if (asndetails === "T") {
        //send request to restlet
        const nsResponse = await axios.post(
          `netsuite/pack-process-v2/create/transaction-packages/restlet`,
          {
            route: nsData.route,
            ifulid: nsData.ifulid,
            packages: nsData.packages,
          }
        );
      }

      return {
        fulfillmentBreakdown: fulfillmentPackDetails.join("\n\n"),
      };
    } catch (error) {
      throw error;
    }
  };
  //logs integration error
  const handleLogPackError = async (error) => {
    await axios.post("integration-errors", {
      errorData: {
        integration: "netsuite",
        type: "pack-process",
        action: "itemfulfillment",
        user: user.username,
        refnumber: createdfrom,
        errormessage: error.response?.data?.msg || error.message,
      },
    });
  };

  //handles input focus (hides keyboard when focused)
  const handleItemRefFocus = () => {
    if (itemInputRef.current !== null) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  };

  if (Boolean(isPalletModalOpen)) {
    // open pallet modal here
    return (
      <PackProcessPalletModal
        close={() => setisPalletModalOpen("")}
        type={isPalletModalOpen}
      />
    );
  }

  if (Boolean(isSearchModalOpen)) {
    // open search modal here
    return (
      <PackProcessSearchItem
        open={isSearchModalOpen}
        close={() => setIsSearchModalOpen(false)}
      />
    );
  }

  return (
    <>
      <FlexWrapper alignItems="center" justifyContent="space-between">
        <FlexWrapper gap={1} alignItems="center">
          <FlexWrapper alignItems="center">
            <IconButton onClick={handleExit}>
              <ExitToApp color="error" />
            </IconButton>
            <IconButton onClick={() => setIsSearchModalOpen(true)}>
              <Search />
            </IconButton>

            {parseInt(totalpacked) === parseInt(totalitemstopack) ? (
              <IconButton
                onClick={() =>
                  dispatch(
                    completePackV2({
                      submitFn: handleCompletePack,
                      order: createdfrom,
                    })
                  )
                }
              >
                <Tooltip open arrow placement="top" title="Complete Pack">
                  <CheckCircle color="success" />
                </Tooltip>
              </IconButton>
            ) : null}
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper alignItems="center">
          {packdetails?.length ? (
            <>
              <ButtonGroup
                size="small"
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <Button
                  size="small"
                  onClick={() => setisPalletModalOpen("edit")}
                >
                  Pallet {packdetails[palletnumber]?.palletId}
                </Button>
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => setOpen(true)}
                >
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Menu
                id="split-button-menu"
                anchorEl={anchorRef?.current}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    maxHeight: 250, // Maximum height of the menu
                    overflowY: "auto", // Enable scrolling within the menu
                  },
                }}
              >
                {packdetails.map((detail) => (
                  <MenuItem
                    key={detail._id}
                    selected={
                      packdetails[palletnumber]?.palletId ===
                      parseInt(detail.palletId)
                    }
                    onClick={() => {
                      dispatch(changePalletNumber(parseInt(detail.palletId)));
                      setOpen(false);
                    }}
                  >
                    Pallet {detail.palletId}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : null}
          {Boolean(packdetails?.length) ? (
            <IconButton onClick={() => setisPalletModalOpen("add")}>
              <Add />
            </IconButton>
          ) : null}
          {packflow === "BOX" ? (
            <>
              <Divider orientation="vertical" flexItem />
              <IconButton
                onClick={() => dispatch(toggleBoxDetailsModal({ type: "add" }))}
              >
                {/* <PackProcessCreateBoxModal
                  palletNumber={packdetails[palletnumber]?.palletId}
                  createdby={user.username}
                  boxes={packdetails[palletnumber]?.boxes}
                  handleItemRefFocus={handleItemRefFocus}
                  _id={_id}
                /> */}
                <DashboardCustomize />
              </IconButton>
            </>
          ) : null}
        </FlexWrapper>
      </FlexWrapper>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <TextField
              type="number"
              inputMode="numeric"
              required
              size="small"
              variant="outlined"
              fullWidth
              label="Qty"
              value={quantity}
              onChange={(event) => setQuantity(event.target.value)}
              inputProps={{ min: 1, autoComplete: "off" }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              inputRef={itemInputRef}
              size="small"
              variant="outlined"
              fullWidth
              label="Enter Item"
              value={search}
              onChange={(event) => setSearch(event.target.value.toUpperCase())}
              inputProps={{ autoComplete: "off" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton type="submit">
                      <QrCodeScanner />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PackProcessHeader;
