import { Box } from "@mui/material";

export const ORDERPROCESSINGCOLUMNS = [
  {
    field: "Date",
    headerName: "Date",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "SO #",
    headerName: "SO #",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
  {
    field: "PO #",
    headerName: "PO #",
    type: "string",
    width: 250,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Status",
    headerName: "Status",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Customer",
    headerName: "Customer",
    type: "string",
    width: 400,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Order Status",
    headerName: "Warehouse Order Status",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
  {
    field: "Shipping Release Status",
    headerName: "Shipping Release Status",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Shipping Method",
    headerName: "Shipping Method",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Picker Assigned",
    headerName: "Picker Assigned",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Projected Pick Qty",
    headerName: "Projected Pick Qty",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Projected Fill Rate %",
    headerName: "Projected Fill Rate %",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Fulfillment Fill Rate %",
    headerName: "Fulfillment Fill Rate %",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Notes",
    headerName: "Warehouse Notes",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Related Transfer Orders",
    headerName: "Related Transfer Orders",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Brand Packaging",
    headerName: "Brand Packaging",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Location",
    headerName: "Location",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Total Qty",
    headerName: "Total Qty",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Must Ship With",
    headerName: "Must Ship With",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Due Date",
    headerName: "Due Date",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Days Left",
    headerName: "Days Left",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  //   {
  //     field: "WHSE READY",
  //     headerName: "WHSE READY",
  //     type: "boolean",
  //     width: 150,
  //     align: "center",
  //     headerAlign: "center",
  //   },
  //   {
  //     field: "WHSE CHECKED TIME",
  //     headerName: "WHSE CHECKED TIME",
  //     type: "date",
  //     width: 180,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => new Date(params.value),
  //   },
  {
    field: "Number of Lines",
    headerName: "Number of Lines",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  //   {
  //     field: "Date/Time Printed",
  //     headerName: "Date/Time Printed",
  //     type: "date",
  //     width: 250,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => new Date(params.value),
  //     valueFormatter: (params) => {
  //       const date = new Date(params.value);
  //       return date.toLocaleString("en-US", {
  //         year: "numeric",
  //         month: "2-digit",
  //         day: "2-digit",
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         hour12: true,
  //       });
  //     },
  //   },
];

export const ORDERPROCESSINGCOLUMNSWGE = [
  {
    field: "Date",
    headerName: "Date",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Ship Date",
    headerName: "Ship Date",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Pick Date",
    headerName: "Pick Date",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => (params.value ? new Date(params.value) : null),
  },
  {
    field: "Customer",
    headerName: "Customer",
    type: "string",
    width: 400,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "PO #",
    headerName: "PO #",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "SO #",
    headerName: "SO #",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
  {
    field: "Status",
    headerName: "Status",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Order Status",
    headerName: "Warehouse Order Status",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
  // {
  //   field: "Shipping Release Status",
  //   headerName: "Shipping Release Status",
  //   type: "string",
  //   width: 200,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Shipping Method",
  //   headerName: "Shipping Method",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Picker Assigned",
  //   headerName: "Picker Assigned",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Projected Pick Qty",
  //   headerName: "Projected Pick Qty",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Projected Fill Rate %",
  //   headerName: "Projected Fill Rate %",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  {
    field: "Fill Rate",
    headerName: "Fill Rate",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Fulfillment Fill Rate %",
    headerName: "Fulfillment Fill Rate %",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Total Qty",
    headerName: "Total Qty",
    type: "number",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Related Transfer Orders",
    headerName: "Related Transfer Orders",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Memo",
    headerName: "Memo",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Notes",
    headerName: "Warehouse Notes",
    type: "string",
    width: 250,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Must Ship With",
    headerName: "Must Ship With",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  // {
  //   field: "Brand Packaging",
  //   headerName: "Brand Packaging",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Location",
  //   headerName: "Location",
  //   type: "string",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Due Date",
  //   headerName: "Due Date",
  //   type: "date",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  //   valueGetter: (params) => new Date(params.value),
  // },
  // {
  //   field: "Days Left",
  //   headerName: "Days Left",
  //   type: "number",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  //   {
  //     field: "WHSE READY",
  //     headerName: "WHSE READY",
  //     type: "boolean",
  //     width: 150,
  //     align: "center",
  //     headerAlign: "center",
  //   },
  //   {
  //     field: "WHSE CHECKED TIME",
  //     headerName: "WHSE CHECKED TIME",
  //     type: "date",
  //     width: 180,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => new Date(params.value),
  //   },
  // {
  //   field: "Number of Lines",
  //   headerName: "Number of Lines",
  //   type: "number",
  //   width: 150,
  //   align: "center",
  //   headerAlign: "center",
  // },
  //   {
  //     field: "Date/Time Printed",
  //     headerName: "Date/Time Printed",
  //     type: "date",
  //     width: 250,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => new Date(params.value),
  //     valueFormatter: (params) => {
  //       const date = new Date(params.value);
  //       return date.toLocaleString("en-US", {
  //         year: "numeric",
  //         month: "2-digit",
  //         day: "2-digit",
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         hour12: true,
  //       });
  //     },
  //   },
  {
    field: "View Pallet Details",
    headerName: "View Pallet Details",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
  {
    field: "Update Pallet Information",
    headerName: "Update Pallet Information",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(params.value);

      if (isHTML) {
        return (
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: params.value }}
          />
        );
      } else {
        return params.value;
      }
    },
  },
];
