import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePrintContentLabels } from "../../../../utils/printer-functions/printContentLabels";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Close, Print } from "@mui/icons-material";
import {
  createPalletV2,
  deletePalletV2,
  editPalletV2,
  removeItemFromPalletV2,
} from "../../../../redux/features/pack-process-v2/packProcessV2Slice";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../FlexWrapper";
import GeneralModalV3 from "../../../general/GeneralModalV3";

const PackProcessPalletModal = ({ close, type }) => {
  const dispatch = useDispatch();
  const { _id, packdetails, palletnumber, createdfrom, ponumber } = useSelector(
    (state) => state.packProcessV2
  );
  const { currentSubsidiary } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);
  const { openAlert } = useGeneralAlert();

  const [error, setError] = useState("");
  const [input, setInput] = useState({
    palletId: "",
    weight: "",
    dimensions: "",
  });

  useEffect(() => {
    if (type) {
      if (type === "edit") {
        setInput({
          palletId: packdetails[palletnumber]?.palletId,
          weight: packdetails[palletnumber]?.weight,
          dimensions: packdetails[palletnumber]?.dimensions,
        });
      } else {
        setInput({
          palletId: packdetails.length + 1,
          weight: 1,
          dimensions: currentSubsidiary === "WGE" ? "120x80x100" : "48x42x50", //default dimensions
        });
      }
    }
  }, []);

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    try {
      if (type === "edit") {
        const pallet = packdetails[palletnumber];

        dispatch(
          editPalletV2({
            _id,
            weight: input.weight,
            dimensions: input.dimensions,
            palletId: pallet._id,
            palletNumber: pallet.palletId,
          })
        );
      } else {
        const foundPallet = packdetails.find(
          (obj) => parseInt(obj.palletId) === parseInt(input.palletId)
        );

        if (foundPallet)
          throw new Error(
            `Pallet Number ${input.palletId} already exists. Pallet numbers must be unique.`
          );

        //create pallet
        dispatch(createPalletV2({ _id, details: input }));
        setError("");
        handleClose();
      }
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    }
  };

  const handleChanges = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value.toUpperCase(),
    });

    if (error) {
      setError("");
    }
  };

  const handleClearPallet = async () => {
    const currentPallet = packdetails[palletnumber];
    const { palletId, items } = currentPallet;

    for (let i = 0; i < items.length; i++) {
      const { item, palletpackedamount, _id: palletItemId } = items[i];

      await dispatch(
        removeItemFromPalletV2({
          _id,
          palletId,
          itemName: item.item,
          palletItemId,
          itemId: item._id,
          removeQty: palletpackedamount,
          last: i === items.length - 1,
          type: "clear-pallet",
        })
      ).unwrap();
    }
  };

  const handleDeletePallet = () => {
    const currentPallet = packdetails[palletnumber];
    dispatch(
      deletePalletV2({
        _id,
        palletNumber: currentPallet?.palletId,
        palletId: currentPallet?._id,
      })
    );
  };

  const printContentLabels = async () => {
    const templateHeader = `^XA^MMT^PW812^LL1218^LS0
    ^FX
    ^FT0,85^FB800,1,0,C,0^A0N,75^FDCONTENTS^FS
    ^FT0,220^FB800,1,0,C,0^A0N,150^FD${createdfrom}^FS
    ^FT0,300^FB800,1,0,C,0^A0N,75^FDPO#: ${ponumber}^FS
    ^FT10,375^FB800,1,0,C,0^A0N,50^FDPALLET: ${
      packdetails[palletnumber]?.palletId < 9
        ? `0${packdetails[palletnumber]?.palletId}`
        : packdetails[palletnumber]?.palletId
    }^FS
    ^FT0,400^GB820,1,4,B^FS
    ^FT0,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT125,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FT425,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT550,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FX`;

    const currentPalletItems = packdetails[palletnumber]?.items;
    const currentPalletBoxes = packdetails[palletnumber]?.boxes;
    let finalItems = [];

    if (currentPalletItems.length) {
      finalItems = [...currentPalletItems];
    }

    if (currentPalletBoxes?.length) {
      currentPalletBoxes.map((boxObj) => {
        if (boxObj.items.length) {
          finalItems = [...finalItems, ...boxObj.items];
        }
      });
    }

    openAlert({
      type: "success",
      message: "Printing Content Labels...",
      duration: 2000,
    });

    try {
      await handlePrintContentLabels({
        templateHeader,
        currentPrinter,
        startingArr: finalItems,
        title: `Print Content Labels for Pallet ${packdetails[palletnumber]?.palletId}`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error?.response?.data?.msg || error.message,
        duration: 5000,
      });
    }
  };

  return (
    <Dialog open={true} fullScreen disableEscapeKeyDown>
      <Container maxWidth="xl">
        <IconButton
          onClick={() => {
            close();
            setError("");
            setInput({
              palletId: "",
              weight: "",
              dimensions: "",
            });
          }}
        >
          <Close />
        </IconButton>
        {type === "edit" && (
          <FlexWrapper justifyContent="flex-end" gap={1} alignItems="center">
            {packdetails[palletnumber]?.totalitemquantity && (
              <Button
                size="small"
                variant="contained"
                onClick={printContentLabels}
                endIcon={<Print color="white" />}
              >
                Content Labels
              </Button>
            )}

            {packdetails[palletnumber]?.totalitemquantity &&
            packdetails[palletnumber]?.boxes?.length === 0 ? (
              <GeneralModalV3
                openComponent={
                  <Button size="small" variant="contained" color="error">
                    Clear Pallet
                  </Button>
                }
              >
                {(handleClose) => (
                  <Box>
                    <Typography
                      textAlign="center"
                      fontWeight="bold"
                      variant="h4"
                      py={1}
                    >
                      Clear Pallet {packdetails[palletnumber]?.palletId}?
                    </Typography>
                    <Divider />
                    <Box py={1}>
                      <Typography textAlign="center" variant="h6" py={1}>
                        All items will be removed from the pallet and the action
                        cannot be undone.
                      </Typography>
                    </Box>
                    <Divider />
                    <FlexWrapper justifyContent="flex-end" p="5px">
                      <Button
                        onClick={() => {
                          handleClose();
                          handleClearPallet();
                        }}
                        variant="contained"
                        color="error"
                      >
                        Clear
                      </Button>
                    </FlexWrapper>
                  </Box>
                )}
              </GeneralModalV3>
            ) : null}
            {packdetails?.length > 1 &&
            packdetails[palletnumber]?.totalitemquantity === 0 ? (
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={handleDeletePallet}
              >
                Delete Pallet
              </Button>
            ) : null}
          </FlexWrapper>
        )}
        <Box>
          <Grid
            container
            spacing={2}
            component="form"
            pt={3}
            onSubmit={handleSubmit}
          >
            <Grid item xs={6}>
              <TextField
                disabled={type === "edit"}
                autoFocus
                fullWidth
                required
                label="Pallet Number"
                name="palletId"
                type="number"
                inputMode="numeric"
                inputProps={{ min: 1, max: 100 }}
                value={input.palletId}
                onChange={handleChanges}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Weight (Optional)"
                name="weight"
                type="number"
                inputMode="numeric"
                value={input.weight}
                onChange={handleChanges}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="dimensions"
                label="Dimensions (Optional)"
                type="text"
                value={input.dimensions}
                onChange={handleChanges}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="p"
                textAlign="center"
                color="error"
              >
                {error}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button size="large" type="submit" fullWidth variant="contained">
                {type === "edit" ? "Edit" : "Add"} Pallet
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
export default PackProcessPalletModal;
