import { useCallback, useEffect, useState } from "react";
import { PACKPROCESSDETAILSITEMSCOLUMNS } from "../../utils/columns/pack-process/packDetailsItemsColumns";
import { useSearchParams } from "react-router-dom";
import { utils, writeFileXLSX } from "xlsx";
import { Download, PictureAsPdf } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  Grid,
  Divider,
} from "@mui/material";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import FlexWrapper from "../FlexWrapper";
import Loading from "../Loading";
import axios from "../../axios/axios.config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicTable from "../BasicTable";
import { convertBase64ToBlobURL } from "../../utils/pdf/helperFunctions";
import { calculateElapsedTime } from "../../utils/helper-functions/general/time";

const PackProcessDetailsResults = ({ orderDetails, shipmentSequences }) => {
  const version = Array.isArray(orderDetails?.packdetails) ? "v2" : "v1";

  const [imageURLS, setImageURLS] = useState(null);
  const [currentPallet, setCurrentPallet] = useState(version === "v1" ? 1 : 0);
  const [palletDetails, setPalletDetails] = useState([]);
  const [itemExportData, setItemExportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pdfURL, setPDFURL] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const matches = useMediaQuery("(max-width:600px)");

  const pallet =
    version === "v1"
      ? orderDetails?.packdetails[`pallet${currentPallet}`]
      : orderDetails?.packdetails[currentPallet];

  useEffect(() => {
    getImageURLS();
    getNetsuitePDF();
  }, []);

  useEffect(() => {
    getPalletBreakdown();
  }, [currentPallet, orderDetails]);

  useEffect(() => {
    getExportData();
  }, [searchParams.get("shipmentsequence")]);

  const getNetsuitePDF = async () => {
    const response = await axios.get(
      `netsuite/pack-process/pack-details/get/transaction-packages-pdf/${searchParams.get(
        "order"
      )}/${searchParams.get("shipmentsequence")}`
    );

    if (response.data?.data64) {
      const url = convertBase64ToBlobURL(response.data.data64);
      setPDFURL(url);
    }
  };

  const getImageURLS = async () => {
    setError(false);
    try {
      const finalImageStorage = {};
      const details =
        version === "v1"
          ? Object.keys(orderDetails.packdetails)
          : orderDetails?.packdetails;

      for (const pallet of details) {
        if (version === "v1") {
          if (orderDetails.packdetails[pallet]?.images?.length) {
            const imagesResponse = await axios.post("files/multi/signedURL", {
              fileKeys: orderDetails.packdetails[pallet].images,
              options: { expiresIn: 3600 },
            });

            finalImageStorage[pallet] = imagesResponse.data.map((url) => {
              return { original: url, thumbnail: url };
            });
          } else {
            finalImageStorage[pallet] = [];
          }
        } else {
          if (pallet?.images?.length) {
            const imagesResponse = await axios.post("files/multi/signedURL", {
              fileKeys: pallet.images,
              options: { expiresIn: 3600 },
            });

            finalImageStorage[`pallet${pallet.palletId}`] =
              imagesResponse.data.map((url) => {
                return { original: url, thumbnail: url };
              });
          } else {
            finalImageStorage[`pallet${pallet.palletId}`] = [];
          }
        }
      }
      if (Object.keys(finalImageStorage).length) {
        setImageURLS(finalImageStorage);
      }
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePalletClick = (event) => {
    setCurrentPallet(parseInt(event.currentTarget.id));
  };

  const handleChangeShipmentSequence = (sequence) => {
    setSearchParams({
      order: orderDetails.createdfrom,
      shipmentsequence: sequence,
    });
  };

  const getPalletBreakdown = () => {
    // Create an object to store quantities by "iful" and "binnumber"
    const quantitiesByItem = {};

    if (version === "v1") {
      // Iterate through the inputArray and accumulate quantities
      orderDetails.packdetails[`pallet${currentPallet}`]?.items?.forEach(
        (item) => {
          const key = item.item;

          if (!quantitiesByItem[key]) {
            quantitiesByItem[key] = {
              item: item.item,
              quantity: item.palletpackedamount || 0,
              iful: [item.iful],
              binnumber: [item.binnumber],
            };
          } else {
            quantitiesByItem[key].quantity += item.palletpackedamount || 0;
            quantitiesByItem[key].iful.push(item.iful);
            quantitiesByItem[key].binnumber.push(item.binnumber);
          }
        }
      );
    } else {
      // Iterate through the inputArray and accumulate quantities V2
      orderDetails.packdetails[currentPallet]?.items?.forEach((itemObj) => {
        const item = itemObj.item;
        const key = item.item;

        if (!quantitiesByItem[key]) {
          quantitiesByItem[key] = {
            item: item.item,
            quantity: itemObj.palletpackedamount || 0,
            iful: [item.iful],
            binnumber: [item.binnumber],
          };
        } else {
          quantitiesByItem[key].quantity += item.palletpackedamount || 0;
          quantitiesByItem[key].iful.push(item.iful);
          quantitiesByItem[key].binnumber.push(item.binnumber);
        }
      });

      //iterate through item boxes
      if (orderDetails.packdetails[currentPallet]?.boxes?.length) {
        for (const box of orderDetails.packdetails[currentPallet]?.boxes) {
          for (const boxItemObj of box.items) {
            const item = boxItemObj.item;
            const key = `${item.item} (BOX ${box.boxId})`;

            if (!quantitiesByItem[key]) {
              quantitiesByItem[key] = {
                item: `${item.item} (BOX ${box.boxId})`,
                quantity: boxItemObj.palletpackedamount || 0,
                iful: [item.iful],
                binnumber: [item.binnumber],
              };
            } else {
              quantitiesByItem[key].quantity += item.palletpackedamount || 0;
              quantitiesByItem[key].iful.push(item.iful);
              quantitiesByItem[key].binnumber.push(item.binnumber);
            }
          }
        }
      }
    }

    // Convert the accumulated quantities object back to an array
    setPalletDetails(Object.values(quantitiesByItem));
  };

  const getExportData = () => {
    const exportData = [];

    if (version === "v1") {
      const pallets = Object.keys(orderDetails.packdetails);
      for (const pallet of pallets) {
        const storage = {}; // will keep count of total item quantity per pallet
        const currentPallet = pallet.replace("pallet", "");

        for (const item of orderDetails.packdetails[pallet].items) {
          const key = item.item;

          if (!storage[key]) {
            storage[key] = {
              "Sales Order": searchParams.get("order"),
              Class: item.itemclass || "",
              Item: item.item,
              Quantity: item.palletpackedamount || 0,
              "Net Weight": parseFloat(item.itemweight) || "",
              "Total Net Weight": "",
              "Weight Units": item.itemweightunits || "",
              "Item Harmonized Code": item.itemharmonizedcode || "",
              Pallet: `PALLET ${parseInt(currentPallet)}`,
              "Shipment Sequence": searchParams.get("shipmentsequence"),
            };
          } else {
            storage[key].Quantity += item.palletpackedamount || 0;
          }

          storage[key]["Total Net Weight"] = storage[key]["Net Weight"]
            ? storage[key]["Net Weight"] * storage[key].Quantity
            : "";
        }
        //add items to export data array
        for (const palletItem of Object.keys(storage)) {
          exportData.push(storage[palletItem]);
        }
      }
    } else {
      for (const packObj of orderDetails.packdetails) {
        const storage = {}; // will keep count of total item quantity per pallet
        for (const itemObj of packObj.items) {
          const item = itemObj.item;
          const key = item.item;

          if (!storage[key]) {
            storage[key] = {
              "Sales Order": searchParams.get("order"),
              "Item Class": item.itemclass || "",
              Item: item.item,
              Quantity: itemObj.palletpackedamount || 0,
              "Net Weight": parseFloat(item.itemweight) || "",
              "Total Net Weight": "",
              "Weight Units": item.itemweightunits || "",
              "Item Harmonized Code": item.itemharmonizedcode || "",
              "Item Subclass": item.itemsubclass || "",
              "Item Sales Description": item.salesdescription || "",
              "Item Cust Ref": item.custref || "",
              "Shipment Sequence": searchParams.get("shipmentsequence"),
              Pallet: `PALLET ${packObj.palletId}`,
            };
          } else {
            storage[key].Quantity += itemObj.palletpackedamount || 0;
          }

          storage[key]["Total Net Weight"] = storage[key]["Net Weight"]
            ? storage[key]["Net Weight"] * storage[key].Quantity
            : "";
        }
        //add box items to export
        if (packObj.boxes?.length) {
          for (const box of packObj?.boxes) {
            for (const boxItemObj of box.items) {
              const item = boxItemObj.item;
              const key = `${item.item} (BOX ${box.boxId})`;

              if (!storage[key]) {
                storage[key] = {
                  "Sales Order": searchParams.get("order"),
                  "Item Class": item.itemclass || "",
                  Item: item.item,
                  Quantity: boxItemObj.palletpackedamount || 0,
                  "Net Weight": parseFloat(item.itemweight) || "",
                  "Total Net Weight": "",
                  "Weight Units": item.itemweightunits || "",
                  "Item Harmonized Code": item.itemharmonizedcode || "",
                  "Item Subclass": item.itemsubclass || "",
                  "Item Sales Description": item.salesdescription || "",
                  "Item Cust Ref": item.custref || "",
                  "Shipment Sequence": searchParams.get("shipmentsequence"),
                  Pallet: `PALLET ${packObj.palletId}`,
                  Box: box.boxId,
                };
              } else {
                storage[key].Quantity += boxItemObj.palletpackedamount || 0;
              }

              storage[key]["Total Net Weight"] = storage[key]["Net Weight"]
                ? storage[key]["Net Weight"] * storage[key].Quantity
                : "";
            }
          }
        }

        //add items to export data array
        for (const palletItem of Object.keys(storage)) {
          exportData.push(storage[palletItem]);
        }
      }
    }

    setItemExportData(exportData);
  };

  const downloadDataAsExcel = useCallback(() => {
    // Create a worksheet
    const ws = utils.json_to_sheet(itemExportData);

    // Create a workbook
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "PACKING LIST");

    //export file
    writeFileXLSX(
      wb,
      `Packing List_${searchParams.get("order")}_${searchParams.get(
        "shipmentsequence"
      )}.xlsx`
    );
  }, [itemExportData]);

  if (loading) {
    return <Loading message="Loading Details..." />;
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        An error occurred: {error}
      </Typography>
    );
  }

  if (!loading) {
    return (
      <>
        <Typography
          variant="h4"
          textAlign="center"
          gutterBottom
          pb={1}
          fontWeight="bold"
        >
          {orderDetails.createdfrom} - {orderDetails.brand}
        </Typography>
        <Typography variant="h6" textAlign="center">
          {Object.keys(orderDetails.itemfulfillments).join(", ")}
        </Typography>
        <Container maxWidth="xl">
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`order-header`}
            >
              <Typography variant="h5">Order Details</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={3}>
                {/* Column 1 */}
                <Grid item xs={12} sm={6}>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText" pr={2}>
                      Customer
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.customer}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      PO Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.ponumber}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Related TOs
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.relatedtos}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Status
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.status.toUpperCase()}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Location
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.location}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Warehouse Notes
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.warehousenotes}
                    </Typography>
                  </FlexWrapper>
                </Grid>

                {/* Column 2 */}
                <Grid item xs={12} sm={6}>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Total Qty Picked
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.totalitemstopack}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Total Qty Packed
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.totalpacked}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Picked By
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.pickedby}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Packed By
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.packedby}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      First Pack Time
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.firstpacktime
                        ? new Date(orderDetails.firstpacktime)?.toLocaleString()
                        : ""}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Last Pack Time
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.lastpacktime
                        ? new Date(orderDetails.lastpacktime)?.toLocaleString()
                        : ""}
                    </Typography>
                  </FlexWrapper>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Typography variant="body1" color="GrayText">
                      Pack Duration
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {orderDetails.firstpacktime && orderDetails.lastpacktime
                        ? calculateElapsedTime(
                            orderDetails.firstpacktime,
                            orderDetails.lastpacktime
                          )
                        : ""}
                    </Typography>
                  </FlexWrapper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ mb: 2 }} />
        </Container>

        <Container maxWidth="xl">
          <FlexWrapper flexDirection={matches ? "column" : "row"} gap={3}>
            <Box sx={{ width: matches ? "100%" : "40%" }}>
              <FlexWrapper
                flexDirection={matches ? "column-reverse" : "row"}
                justifyContent="space-between"
              >
                <Box sx={{ pb: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Shipment Sequences
                  </Typography>
                  <Stack spacing={2} direction="row" flexWrap="wrap">
                    {shipmentSequences.map((sequence) => (
                      <Chip
                        key={sequence}
                        id={sequence}
                        label={sequence}
                        color={"info"}
                        variant={
                          sequence ===
                          parseInt(searchParams.get("shipmentsequence"))
                            ? "filled"
                            : "outlined"
                        }
                        onClick={() => handleChangeShipmentSequence(sequence)}
                      />
                    ))}
                  </Stack>
                </Box>

                <Box sx={{ width: matches ? "100%" : "initial" }}>
                  <FlexWrapper gap={1} alignItems="center">
                    {pdfURL ? (
                      <Link href={pdfURL} target="_blank">
                        <IconButton>
                          <PictureAsPdf />
                        </IconButton>
                      </Link>
                    ) : null}

                    {Boolean(itemExportData.length) && (
                      <Tooltip title="Download to Excel" placement="top" arrow>
                        <IconButton onClick={() => downloadDataAsExcel()}>
                          <Download />
                        </IconButton>
                      </Tooltip>
                    )}
                  </FlexWrapper>
                </Box>
              </FlexWrapper>

              <Box sx={{ pb: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Pallet
                </Typography>
                <Stack spacing={2} direction="row" flexWrap="wrap">
                  {version === "v1"
                    ? Object.keys(orderDetails.packdetails).map(
                        (pallet, index) => (
                          <Chip
                            key={pallet}
                            id={index + 1}
                            label={pallet.replace("pallet", "")}
                            color={"info"}
                            variant={
                              index + 1 === currentPallet
                                ? "filled"
                                : "outlined"
                            }
                            onClick={handlePalletClick}
                          />
                        )
                      )
                    : orderDetails.packdetails.map((pallet, index) => (
                        <Chip
                          key={pallet._id}
                          id={index}
                          label={`Pallet ${pallet.palletId}`}
                          color={"info"}
                          variant={
                            index === currentPallet ? "filled" : "outlined"
                          }
                          onClick={handlePalletClick}
                        />
                      ))}
                </Stack>
              </Box>
              <Accordion elevation={0} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`pallet-${
                    version === "v1" ? currentPallet : pallet.palletId
                  }-header`}
                >
                  <Typography variant="h5">
                    {version === "v1"
                      ? `Pallet ${currentPallet} Details`
                      : `Pallet ${pallet.palletId} Details`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    <FlexWrapper
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Typography variant="body1" color="GrayText">
                        Weight
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        {pallet.weight}
                      </Typography>
                    </FlexWrapper>
                    <FlexWrapper
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Typography variant="body1" color="GrayText">
                        Dimensions
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        {pallet.dimensions}
                      </Typography>
                    </FlexWrapper>
                    <FlexWrapper
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <Typography variant="body1" color="GrayText">
                        Total Pallet Quantity
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        {pallet.totalitemquantity || null}
                      </Typography>
                    </FlexWrapper>
                    {Boolean(palletDetails.length) && (
                      <BasicTable
                        columns={PACKPROCESSDETAILSITEMSCOLUMNS}
                        rows={palletDetails}
                      />
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              sx={{
                width: matches ? "100%" : "55%",
              }}
            >
              {imageURLS[
                `pallet${version === "v1" ? currentPallet : pallet.palletId}`
              ]?.length ? (
                <ImageGallery
                  showPlayButton={false}
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    boxShadow: "#2b15453d 1px 10px 10px 5px",
                  }}
                  items={
                    imageURLS[
                      `pallet${
                        version === "v1" ? currentPallet : pallet.palletId
                      }`
                    ]
                  }
                />
              ) : (
                !matches && (
                  <FlexWrapper justifyContent="center">
                    <Typography variant="h4" textAlign="center">
                      NO IMAGES
                    </Typography>
                  </FlexWrapper>
                )
              )}
            </Box>
          </FlexWrapper>
        </Container>
      </>
    );
  }
};
export default PackProcessDetailsResults;
