import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openGeneralAlert } from "../alert/alertSlice";
import { handleErrorBeep } from "../../../utils/helper-functions/general/errors";

import axios from "../../../axios/axios.config";

const initialState = {
  items: [],
  asndetails: null,
  ucclabel: null,
  status: null,
  createdby: null,
  pickedby: null,
  subsidiary: null,
  type: null,
  internalid: null,
  createdfromtype: null,
  createdfrom: null,
  createdfrominternalid: null,
  ponumber: null,
  customer: null,
  customerinternalid: null,
  brand: null,
  location: null,
  locationinternalid: null,
  warehousenotes: null,
  relatedtos: null,
  memo: null,
  totalpacked: null,
  totalitemstopack: null,
  itemfulfillments: null,
  palletimages: null,
  shipmentsequence: null,
  createdfrommemo: null,
  createdfromnotes: null,
  createdfromprioritystatus: null,
  createdfromdate: null,
  createdfromsoreference: null,
  discrepancies: null,
  starttime: null,
  firstpacktime: null,
  lastpacktime: null,
  packlockedby: null,
  packdetails: [],
  createdAt: null,
  updatedAt: null,
  loading: false,
  palletnumber: 0,
  errors: [],
  scans: [],
  tabValue: 0,
  isBoxModalOpen: false,
  boxDetails: null,
};

export const loadPackDataV2 = createAsyncThunk(
  "pack-process-v2/loadPackDataV2",
  async (_id, thunkAPI) => {
    try {
      const response = await axios.get(`pack-process-v2/${_id}`);

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Loaded ${response.data?.createdfrom}`,
          duration: 2000,
        })
      );

      return {
        ...response.data,
        palletnumber: response?.data?.packdetails?.length
          ? response?.data?.packdetails?.length - 1
          : 0,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
      });
    }
  }
);

export const createPalletV2 = createAsyncThunk(
  "pack-process-v2/createPalletV2",
  async ({ _id, details }, thunkAPI) => {
    try {
      const response = await axios.patch(`pack-process-v2/add-pallet/${_id}`, {
        palletDetails: details,
      });

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully created pallet ${details.palletId}`,
          duration: 2000,
        })
      );

      return {
        packdetails: response.data.packdetails,
        newPalletIndex: response.data.packdetails.findIndex(
          (obj) => parseInt(obj.palletId) === parseInt(details.palletId)
        ),
        scans: response.data.scans,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "pallet",
      });
    }
  }
);

export const createPalletBoxV2 = createAsyncThunk(
  "pack-process-v2/createPalletBox",
  async (
    { _id, createdby, boxId, palletId, dimensions = "", weight = 1 },
    thunkAPI
  ) => {
    try {
      const response = await axios.patch(
        `pack-process-v2/create-pallet-box/${_id}`,
        {
          boxId,
          createdby,
          palletId,
          dimensions,
          weight,
        }
      );

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully created Box ${boxId} in Pallet ${palletId}`,
          duration: 2000,
        })
      );

      return {
        packdetails: response.data.packdetails,
        newBox: boxId,
        scans: response.data.scans,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "pallet",
      });
    }
  }
);

export const editPalletV2 = createAsyncThunk(
  "pack-process-v2/editPalletV2",
  async ({ _id, weight, dimensions, palletId, palletNumber }, thunkAPI) => {
    try {
      const response = await axios.patch(`pack-process-v2/edit-pallet/${_id}`, {
        weight,
        dimensions,
        palletId,
      });

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully edited pallet ${palletNumber}`,
          duration: 2000,
        })
      );

      return {
        packdetails: response.data.packdetails,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "pallet",
      });
    }
  }
);

export const editPalletBoxV2 = createAsyncThunk(
  "pack-process-v2/editPalletBoxV2",
  async ({ _id, weight, dimensions, palletId, boxNumber, boxId }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process-v2/edit-pallet-box/${_id}`,
        {
          weight,
          dimensions,
          palletId, //pallet number
          boxId, // box mongo id
        }
      );

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully Edited Box ${boxNumber} in Pallet ${palletId}`,
          duration: 2000,
        })
      );

      return {
        packdetails: response.data.packdetails,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "pallet",
      });
    }
  }
);

export const deletePalletV2 = createAsyncThunk(
  "pack-process-v2/deletePalletV2",
  async ({ _id, palletNumber, palletId }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `pack-process-v2/delete-pallet/${_id}`,
        {
          palletId,
          palletNumber,
        }
      );
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully deleted pallet ${palletNumber}`,
          duration: 2000,
        })
      );
      return {
        scans: response.data?.scans,
        packdetails: response.data?.packdetails,
        palletnumber: response.data?.packdetails?.length - 1,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "pallet",
      });
    }
  }
);

export const scanItemToPalletV2 = createAsyncThunk(
  "pack-process-v2/scanItemToPalletV2",
  async (
    { _id, item, quantity, palletId, firstpacktime, username },
    thunkAPI
  ) => {
    try {
      if (!item || !quantity) {
        throw new Error("Missing one or more required fields: Item, Quantity");
      }

      const response = await axios.patch(`pack-process-v2/scan-item/${_id}`, {
        item,
        quantity,
        palletId,
        firstpacktime,
        username,
      });

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully scanned (${quantity}) ${item}  in pallet ${palletId}`,
          duration: 2000,
        })
      );

      return response.data;
    } catch (error) {
      let errorData = {
        scantype: "error",
        scanqty: quantity,
        pelletnumber: palletId,
        item: error.response?.data?.errorItem?.item || item,
        upc: error.response?.data?.errorItem?.upc || null,
        error: error.response?.data?.msg || error.message,
      };

      let response = null;

      try {
        // Try to dispatch the error scan to the server
        response = await axios.patch(`pack-process-v2/add-scan/${_id}`, {
          newScan: errorData,
        });
      } catch (axiosError) {
        // If the axios request fails, log the error (optional) and return a fallback value
        handleErrorBeep();
        return thunkAPI.rejectWithValue({
          msg:
            "Failed to log scan error to server. Original error: " +
            error.message,
          type: "scan",
          scans: null, // No scans available in case of failure
        });
      }
      handleErrorBeep();
      // Return the original error if the axios request succeeds
      return thunkAPI.rejectWithValue({
        msg: errorData.error,
        type: "scan",
        scans: response?.data?.scans || null,
      });
    }
  }
);

export const scanItemToPalletBoxV2 = createAsyncThunk(
  "pack-process-v2/scanItemToPalletBoxV2",
  async (
    {
      _id,
      item,
      quantity,
      palletId,
      firstpacktime,
      username,
      boxId,
      boxNumber,
    },
    thunkAPI
  ) => {
    try {
      if (!item || !quantity) {
        throw new Error("Missing one or more required fields: Item, Quantity");
      }

      const response = await axios.patch(
        `pack-process-v2/scan-item-to-box/${_id}`,
        {
          palletId,
          boxId,
          item,
          quantity,
          firstpacktime,
          username,
        }
      );

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully scanned (${quantity}) ${item} in pallet ${palletId} (Box: ${boxNumber})`,
          duration: 2000,
        })
      );

      return response.data;
    } catch (error) {
      let errorData = {
        scantype: "error",
        scanqty: quantity,
        pelletnumber: palletId,
        item: error.response?.data?.errorItem?.item || item,
        upc: error.response?.data?.errorItem?.upc || null,
        error: error.response?.data?.msg || error.message,
        boxnumber: boxNumber,
      };

      let response = null;

      try {
        // Try to dispatch the error scan to the server
        response = await axios.patch(`pack-process-v2/add-scan/${_id}`, {
          newScan: errorData,
        });
      } catch (axiosError) {
        handleErrorBeep();
        // If the axios request fails, log the error (optional) and return a fallback value
        return thunkAPI.rejectWithValue({
          msg:
            "Failed to log scan error to server. Original error: " +
            error.message,
          type: "scan",
          scans: null, // No scans available in case of failure
        });
      }
      handleErrorBeep();
      // Return the original error if the axios request succeeds
      return thunkAPI.rejectWithValue({
        msg: errorData.error,
        type: "scan",
        scans: response?.data?.scans || null,
      });
    }
  }
);

export const removeItemFromPalletBoxV2 = createAsyncThunk(
  "pack-process-v2/removeItemFromPalletBoxV2",
  async (
    {
      _id,
      item,
      itemId,
      removeQty,
      palletId,
      boxId,
      boxNumber,
      palletItemId,
      last = true,
      type = "clear-item",
    },
    thunkAPI
  ) => {
    try {
      let scans = null;
      const response = await axios.patch(
        `pack-process-v2/remove-item-from-box/${_id}`,
        {
          palletId,
          itemId, //object id
          palletItemId, //object id
          boxId, //object id
          removeQty,
        }
      );

      if (last && type === "clear-box") {
        const scanResponse = await axios.patch(
          `pack-process-v2/add-scan/${_id}`,
          {
            newScan: {
              type: "action", //for type actions only
              scantype: "success",
              action: `Successfully Cleared Box ${boxNumber} in Pallet ${palletId}`,
            },
          }
        );
        scans = scanResponse?.data?.scans;
      }

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully removed (${removeQty}) ${item} from pallet ${palletId} (Box: ${boxNumber})`,
          duration: 2000,
        })
      );

      if (scans) {
        return { ...response.data, last, scans };
      }

      return { ...response.data, last };
    } catch (error) {
      // Return the original error if the axios request succeeds
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "delete",
      });
    }
  }
);

export const deleteBoxFromPalletV2 = createAsyncThunk(
  "pack-process-v2/deleteBoxFromPalletV2",
  async ({ _id, palletId, boxId, boxNumber }, thunkAPI) => {
    try {
      const response = await axios.patch(`pack-process-v2/delete-box/${_id}`, {
        boxId, //object id
        boxNumber, //used for scans
        palletId, //used for scans
      });

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully deleted Box ${boxNumber} from Pallet ${palletId}`,
          duration: 2000,
        })
      );

      return response.data;
    } catch (error) {
      // Return the original error if the axios request succeeds
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "delete",
      });
    }
  }
);

export const removeItemFromPalletV2 = createAsyncThunk(
  "pack-process-v2/removeItemFromPalletV2",
  async (
    {
      _id,
      palletId,
      itemName,
      palletItemId,
      itemId,
      removeQty,
      last = true,
      type = "clear-item",
    },
    thunkAPI
  ) => {
    try {
      let scans = null;
      const response = await axios.patch(
        `pack-process-v2/remove-item-from-pallet/${_id}`,
        {
          palletItemId,
          itemId,
          removeQty,
          palletId,
        }
      );

      if (last && type === "clear-pallet") {
        const scanResponse = await axios.patch(
          `pack-process-v2/add-scan/${_id}`,
          {
            newScan: {
              type: "action", //for type actions only
              scantype: "success",
              action: `Successfully Cleared Pallet ${palletId}`,
            },
          }
        );
        scans = scanResponse?.data?.scans;
      }

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully removed ${itemName} (${removeQty}) from pallet ${palletId}`,
          duration: 3000,
        })
      );

      if (scans) {
        return { ...response.data, last, scans, type };
      }

      return { ...response.data, last };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "remove-item",
      });
    }
  }
);

export const completePackV2 = createAsyncThunk(
  "pack-process-v2/completePackV2",
  async ({ submitFn, order }, thunkAPI) => {
    try {
      await submitFn();

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "success",
          message: `Successfully completed ${order}`,
          duration: 2000,
        })
      );

      return;
    } catch (error) {
      handleErrorBeep();
      return thunkAPI.rejectWithValue({
        msg: error.response?.data?.msg || error.message,
        type: "scan",
      });
    }
  }
);

const packProcessV2Slice = createSlice({
  name: "pack-process-v2",
  initialState,
  reducers: {
    setInitialPackDataV2: (_, { payload }) => {
      return { ...initialState, ...payload };
    },
    clearPackErrorsV2: (state) => {
      state.errors = [];
    },
    exitPackProcessV2: () => {
      return initialState;
    },
    changePalletNumber: (state, { payload }) => {
      state.palletnumber = state.packdetails.findIndex(
        (detail) => detail.palletId === payload
      );
    },
    changeTabValue: (state, { payload }) => {
      state.tabValue = payload;
    },
    toggleCurrentBox: (state, { payload }) => {
      state.isBoxModalOpen = payload;
    },
    toggleBoxDetailsModal: (state, { payload }) => {
      state.boxDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPackDataV2.pending, (state) => {
      state.loading = "Loading Pack Data...";
    });
    builder.addCase(loadPackDataV2.fulfilled, (state, { payload }) => {
      return { ...initialState, ...payload };
    });
    builder.addCase(loadPackDataV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(createPalletV2.pending, (state) => {
      state.loading = "Creating Pallet...";
    });
    builder.addCase(createPalletV2.fulfilled, (state, { payload }) => {
      state.packdetails = payload.packdetails;
      state.scans = payload.scans;
      state.loading = "";
      state.palletnumber = parseInt(payload.newPalletIndex);
    });
    builder.addCase(createPalletV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(createPalletBoxV2.pending, (state) => {
      state.loading = "Creating Box...";
    });
    builder.addCase(createPalletBoxV2.fulfilled, (state, { payload }) => {
      state.packdetails = payload.packdetails;
      state.isBoxModalOpen = payload.newBox;
      state.scans = payload.scans;
      state.loading = "";
    });
    builder.addCase(createPalletBoxV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(editPalletV2.pending, (state) => {
      state.loading = "Editing Pallet...";
    });
    builder.addCase(editPalletV2.fulfilled, (state, { payload }) => {
      state.packdetails = payload.packdetails;
      state.loading = "";
    });
    builder.addCase(editPalletV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(editPalletBoxV2.pending, (state) => {
      state.loading = "Editing Box...";
    });
    builder.addCase(editPalletBoxV2.fulfilled, (state, { payload }) => {
      state.packdetails = payload.packdetails;
      state.loading = "";
    });
    builder.addCase(editPalletBoxV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(scanItemToPalletV2.fulfilled, (state, { payload }) => {
      state.items = payload.items;
      state.packdetails = payload.packdetails;
      state.totalpacked = payload.totalpacked;
      state.scans = payload.scans;
      state.lastpacktime = payload.lastpacktime;
      if (state.firstpacktime !== payload.firstpacktime) {
        state.firstpacktime = payload.firstpacktime;
      }
    });
    builder.addCase(scanItemToPalletV2.rejected, (state, { payload }) => {
      if (payload.scans) {
        state.scans = payload.scans;
      }
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(scanItemToPalletBoxV2.fulfilled, (state, { payload }) => {
      state.items = payload.items;
      state.packdetails = payload.packdetails;
      state.totalpacked = payload.totalpacked;
      state.scans = payload.scans;
      state.lastpacktime = payload.lastpacktime;
      if (state.firstpacktime !== payload.firstpacktime) {
        state.firstpacktime = payload.firstpacktime;
      }
    });
    builder.addCase(scanItemToPalletBoxV2.rejected, (state, { payload }) => {
      if (payload.scans) {
        state.scans = payload.scans;
      }
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(completePackV2.pending, (state) => {
      state.loading = "Completing Pack...";
    });
    builder.addCase(completePackV2.fulfilled, (state) => {
      state.loading = "";
    });
    builder.addCase(completePackV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(removeItemFromPalletV2.pending, (state) => {
      state.loading = "Removing Item...";
    });
    builder.addCase(removeItemFromPalletV2.fulfilled, (state, { payload }) => {
      state.scans = payload.scans;
      state.packdetails = payload.packdetails;
      state.items = payload.items;
      state.totalpacked = payload.totalpacked;
      if (payload.last) {
        state.loading = "";
      }
    });
    builder.addCase(removeItemFromPalletV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(deletePalletV2.pending, (state) => {
      state.loading = "Deleting Pallet...";
    });
    builder.addCase(deletePalletV2.fulfilled, (state, { payload }) => {
      state.packdetails = payload.packdetails;
      state.scans = payload.scans;
      state.palletnumber = payload.palletnumber;
      state.loading = "";
    });
    builder.addCase(deletePalletV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(deleteBoxFromPalletV2.pending, (state) => {
      state.loading = "Deleting Box...";
    });
    builder.addCase(deleteBoxFromPalletV2.fulfilled, (state, { payload }) => {
      state.isBoxModalOpen = false;
      state.packdetails = payload.packdetails;
      state.scans = payload.scans;
      state.loading = "";
    });
    builder.addCase(deleteBoxFromPalletV2.rejected, (state, { payload }) => {
      state.errors.push(payload);
      state.loading = "";
    });
    builder.addCase(removeItemFromPalletBoxV2.pending, (state) => {
      state.loading = "Removing Item From Box...";
    });
    builder.addCase(
      removeItemFromPalletBoxV2.fulfilled,
      (state, { payload }) => {
        state.scans = payload.scans;
        state.packdetails = payload.packdetails;
        state.items = payload.items;
        state.totalpacked = payload.totalpacked;
        if (payload.last) {
          state.loading = "";
        }
      }
    );
    builder.addCase(
      removeItemFromPalletBoxV2.rejected,
      (state, { payload }) => {
        state.errors.push(payload);
        state.loading = "";
      }
    );
  },
});

export const {
  setInitialPackDataV2,
  clearPackErrorsV2,
  exitPackProcessV2,
  changePalletNumber,
  changeTabValue,
  toggleCurrentBox,
  toggleBoxDetailsModal,
} = packProcessV2Slice.actions;

export default packProcessV2Slice.reducer;
