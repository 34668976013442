import { useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { resizeFile } from "../../../../utils/files/resizeFile";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ExitToApp,
  Print,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Loading from "../../../Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../FlexWrapper";
import EditDiscrepanciesModal from "../../../warehouse/pack-process/update-pack-order/EditDiscrepanciesModal";
import ViewAWSImages from "../../../warehouse/pack-process/update-pack-order/ViewAWSImages";
import GeneralFileUpload from "../../../GeneralFileUpload";
import GeneralModalV3 from "../../../general/GeneralModalV3";
import { handlePrintContentLabels } from "../../../../utils/printer-functions/printContentLabels";

const UpdatePackResultsV2 = ({ data }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { openAlert } = useGeneralAlert();
  const { orderid } = useParams();
  const { username, currentDepartment } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);

  const [palletData, setPalletData] = useState(data?.packDetails);
  const [currentPalletIndex, setCurrentPalletIndex] = useState(0);
  const [loading, setLoading] = useState("");
  const [discrepancies, setDiscrepanices] = useState(data?.discrepancies || 0);

  const currentPallet = palletData[currentPalletIndex];
  const currentImages = palletData[currentPalletIndex]?.images;

  if (navigation.state === "loading") {
    return <Loading message="Navigating..." />;
  }

  if (loading) {
    return <Loading message={loading} />;
  }

  const handleSaveFiles = async (files) => {
    try {
      const resizedFiles = [];
      for (const f of files) {
        const resizedFile = await resizeFile({
          file: new File(
            [f],
            `Pallet_${currentPallet?.palletId}_${Date.now()}.`,
            {
              type: f.type,
              lastModified: f.lastModified,
            }
          ),
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "jpeg",
          quality: 80,
          outputType: "file",
        });
        resizedFiles.push(resizedFile);
      }
      const newPalletData = [...palletData];
      //update images for current pallet
      newPalletData[currentPalletIndex].newImages = [
        ...newPalletData[currentPalletIndex].newImages,
        ...resizedFiles,
      ];

      newPalletData[currentPalletIndex].isupdated = true;
      setPalletData(newPalletData);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error.message}`,
        duration: 3000,
      });
    }
  };

  const handleRemoveFile = (file) => {
    const newPalletData = [...palletData];
    //update images for current pallet
    newPalletData[currentPalletIndex].newImages = newPalletData[
      currentPalletIndex
    ].newImages.filter((fileObj) => fileObj.name !== file.name);

    setPalletData(newPalletData);
  };

  const handleInputChanges = (event) => {
    const newData = [...palletData];
    if (
      event.target.name === "discrepancies" ||
      event.target.name === "weight"
    ) {
      newData[currentPalletIndex][event.target.name] = parseInt(
        event.target.value
      );
    } else {
      newData[currentPalletIndex][event.target.name] = event.target.value;
    }
    newData[currentPalletIndex].isupdated = true;
    setPalletData(newData);
  };

  const handlePrevPallet = () => {
    if (currentPalletIndex === 0) return;
    setCurrentPalletIndex(currentPalletIndex - 1);
  };

  const handleNextPallet = () => {
    if (currentPalletIndex === data?.packDetails?.length - 1) return;

    setCurrentPalletIndex(currentPalletIndex + 1);
  };

  //will update mongo and update netsuite
  const handleUpdatePalletData = async () => {
    setLoading("Updating Pallet Details...");

    const updatedPallets = palletData.filter((pallet) => pallet.isupdated);
    let errorPallet = null;

    try {
      let response = null;
      //if no new updates, just pull latest from mongo and update netsuite
      if (!updatedPallets.length) {
        response = await axios.get(`pack-process-v2/${orderid}?populate=false`);
      } else {
        //updates were made
        const newMongoData = [];
        //loop through updated pallets and check rquirements and save files to S3
        for (let i = 0; i < palletData.length; i++) {
          const currentPalletObj = palletData[i];
          errorPallet = i;

          if (currentPalletObj.isupdated) {
            if (!currentPalletObj?.weight || currentPalletObj?.weight <= 1) {
              throw new Error(
                `Incorrect weight for Pallet ${currentPalletObj?.palletId}`
              );
            }

            if (!currentPalletObj?.dimensions) {
              throw new Error(
                `Pallet ${currentPalletObj?.palletId} is missing required fields:  Dimensions`
              );
            }
            //only errors out when images are required. Only checks pallets that are updated by user, others ignored
            if (
              data?.isImagesRequired === "T" &&
              currentPalletObj?.images?.length +
                currentPalletObj?.newImages?.length <
                4
            ) {
              throw new Error(
                `Pallet ${currentPalletObj?.palletId} requires at least 4 images`
              );
            }

            //Save File to S3
            const imageKeys = [];
            const formData = new FormData();
            const folder = `warehouse/packprocess/palletimages/${data?.order}/`;
            formData.append("folder", folder);

            if (currentPalletObj?.newImages.length) {
              currentPalletObj?.newImages.forEach((file) => {
                const imageKey = `${file.name}`;
                //will store keys in mongo to generate URLs once saved
                imageKeys.push(folder + imageKey);
                formData.append("files", file);
              });

              //upload images to s3
              await axios.post("/files/upload/multi", formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });
            }

            const palletUpdate = {
              ...currentPalletObj,
              weight: currentPalletObj?.weight,
              dimensions: currentPalletObj?.dimensions,
              images: [...currentPalletObj.images, ...imageKeys],
            };
            delete palletUpdate.newImages;
            delete palletUpdate.isupdated;

            newMongoData.push(palletUpdate);
          } else {
            newMongoData.push(currentPalletObj);
          }
        }

        //update mongo with new mongo Data
        response = await axios.patch(
          `pack-process-v2/update-pack-order/update-details/${orderid}/`,
          { newMongoData }
        );
      }

      if (!response)
        throw new Error("Could not get order data. Please try again.");

      //Use Updated data to make netsuite request
      //check if tran packages record exists
      const tranRecordCheckResponse = await axios.get(
        `netsuite/pack-process/get/transaction-packages/${response.data?.createdfrom}/${response.data?.shipmentsequence}`
      );

      const tranPckgData = tranRecordCheckResponse.data;
      //only delete if tran packages exist
      if (tranPckgData.count) {
        const packageId = tranPckgData.items[0].id;
        await axios.delete(
          `netsuite/pack-process/delete/transaction-packages/${packageId}`
        );
      }

      //create tran packages record (this is where we set header fields)
      const createTranPackageResponse = await axios.post(
        "netsuite/pack-process/create/transaction-packages",
        {
          name: response.data?.createdfrom,
          username,
          shipSequence: response.data?.shipmentsequence,
          discrepancies: response.data?.discrepancies,
        }
      );
      //tran packages record id
      const recordid = createTranPackageResponse.data?.recordid;

      //create the pallets linked to new recordid (set pallet level fields)
      for (const pallet of response.data?.packdetails) {
        await axios.post(
          "netsuite/pack-process/create/transaction-packages/pallet",
          {
            recordid, //Internal ID of the transaction packages record created in previous step
            palletnumber: pallet?.palletId, //Pallet Number as Integer
            palletweight: parseFloat(pallet?.weight), //Pallet Weight as Float
            palletdim: pallet?.dimensions, //Pallet Dimensions as String
          }
        );
      }

      //send a request to force tran packages page to update on netsuite to build tran packages breakdown
      await axios.patch("netsuite/pack-process/update/transaction-packages", {
        recordid,
      });

      navigate(`/portal/${currentDepartment?.toLowerCase()}/update-pack-order`);
      openAlert({
        type: "success",
        message: `Successfully Updated ${data?.order}`,
        duration: 5000,
      });
    } catch (error) {
      if (errorPallet !== null) {
        setCurrentPalletIndex(errorPallet);
      }
      openAlert({
        type: "error",
        message: `Could Not Update Data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  const printContentLabels = async () => {
    const templateHeader = `^XA^MMT^PW812^LL1218^LS0
    ^FX
    ^FT0,85^FB800,1,0,C,0^A0N,75^FDCONTENTS^FS
    ^FT0,220^FB800,1,0,C,0^A0N,150^FD${data?.order}^FS
    ^FT0,300^FB800,1,0,C,0^A0N,75^FDPO#: ${data?.ponumber}^FS
    ^FT10,375^FB800,1,0,C,0^A0N,50^FDPALLET: ${
      currentPallet?.palletId < 9
        ? `0${currentPallet?.palletId}`
        : currentPallet?.palletId
    }^FS
    ^FT0,400^GB820,1,4,B^FS
    ^FT0,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT125,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FT425,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    ^FT550,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    ^FX`;

    const currentPalletItems = currentPallet?.items;
    const currentPalletBoxes = currentPallet?.boxes;
    let finalItems = [];

    if (currentPalletItems.length) {
      finalItems = [...currentPalletItems];
    }

    if (currentPalletBoxes?.length) {
      currentPalletBoxes.map((boxObj) => {
        if (boxObj.items.length) {
          finalItems = [...finalItems, ...boxObj.items];
        }
      });
    }

    openAlert({
      type: "success",
      message: "Printing Content Labels...",
      duration: 2000,
    });

    try {
      await handlePrintContentLabels({
        templateHeader,
        currentPrinter,
        startingArr: finalItems,
        title: `Print Content Labels for Pallet ${currentPallet?.palletId}`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: error?.response?.data?.msg || error.message,
        duration: 5000,
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" textAlign="center" gutterBottom>
        {data?.order} - {data?.brand}
      </Typography>

      <FlexWrapper justifyContent="space-between" gap={1}>
        <GeneralModalV3
          openComponent={
            <IconButton>
              <ExitToApp color="error" />
            </IconButton>
          }
        >
          {(handleClose) => (
            <Box>
              <Typography textAlign="center" variant="h5" p={1}>
                Exit Page?
              </Typography>
              <Divider />
              <Typography fontWeight="bold" textAlign="center" py={2}>
                Make sure you have submitted data before exiting. All unsaved
                data will be lost.
              </Typography>
              <Divider />
              <FlexWrapper justifyContent="flex-end">
                <Button
                  sx={{ mt: 1, mr: 1 }}
                  size="large"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    handleClose();
                    navigate(
                      `/portal/${currentDepartment?.toLowerCase()}/update-pack-order`
                    );
                  }}
                >
                  Exit
                </Button>
              </FlexWrapper>
            </Box>
          )}
        </GeneralModalV3>

        {/* Discrepanices Modal*/}
        <EditDiscrepanciesModal
          discrepancies={discrepancies}
          setDiscrepanices={setDiscrepanices}
          openAlert={openAlert}
          id={orderid}
          type="v2"
        />

        <IconButton
          color="primary"
          disabled={!Boolean(currentPallet.totalitemquantity)}
          onClick={printContentLabels}
        >
          <Tooltip title="Print Content Labels" arrow placement="top">
            <Print />
          </Tooltip>
        </IconButton>
      </FlexWrapper>

      {/* Pallet Changer goes here */}
      <Box display="flex" alignItems="center" justifyContent="center" py={2}>
        <IconButton
          disabled={currentPalletIndex === 0}
          onClick={handlePrevPallet}
        >
          <Tooltip title="Previous" arrow placement="top">
            <ArrowBackIos />
          </Tooltip>
        </IconButton>

        <FormControl variant="outlined" sx={{ minWidth: 200, mx: 1 }}>
          <Select
            labelId="pallet-select-label"
            value={currentPalletIndex}
            onChange={(event) => setCurrentPalletIndex(event.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {data?.packDetails?.map((pallet, index) => (
              <MenuItem key={pallet._id} value={index}>
                Pallet {pallet?.palletId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          onClick={handleNextPallet}
          disabled={currentPalletIndex === data?.packDetails?.length - 1}
        >
          <Tooltip title="Next" arrow placement="top">
            <ArrowForwardIos />
          </Tooltip>
        </IconButton>
      </Box>

      <Box pt={1}>
        <Typography variant="h5" gutterBottom pb={1}>
          Pallet Information{" "}
          <Typography component="span" variant="caption" fontWeight="bold">
            (Pallet Qty: {currentPallet.totalitemquantity} )
          </Typography>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              required
              type="number"
              label="Pallet Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentPallet?.palletId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Enter Weight"
              name="weight"
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              value={currentPallet?.weight}
              onChange={handleInputChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="dimensions"
              label="Enter Dimensions"
              type="text"
              value={currentPallet?.dimensions}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChanges}
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h5" gutterBottom>
            Images
          </Typography>
          {currentImages.length ? (
            <ViewAWSImages
              currentImages={currentImages}
              openAlert={openAlert}
            />
          ) : null}
        </FlexWrapper>

        {data?.isImagesRequired === "T" ? (
          <Typography color="error" variant="caption" pb={1} fontWeight="bold">
            Images are required for this order
          </Typography>
        ) : null}

        <>
          <GeneralFileUpload
            multiple
            handleSaveFiles={handleSaveFiles}
            accept=".jpg,.jpeg,.png"
            handleRemoveFile={handleRemoveFile}
            files={currentPallet?.newImages}
          />
          <Typography
            variant="caption"
            pt={1}
            fontWeight="bold"
            textTransform="capitalize"
          >
            Upload images of each side of the pallet
          </Typography>
        </>
      </Box>

      <Box py={2}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleUpdatePalletData}
        >
          Update Pallets
        </Button>
      </Box>
    </Container>
  );
};
export default UpdatePackResultsV2;
