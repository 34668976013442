import axios from "../../axios/axios.config";
import { scaleZPL } from "./scaleZPL";

export const handlePrintContentLabels = async ({
  startingArr,
  colSize = 22,
  currentPrinter = null,
  templateHeader = "",
  title,
}) => {
  if (!currentPrinter) {
    throw new Error(
      "No Printer is assigned. Please select a printer in your settings."
    );
  }

  const finalTemplates = []; //will store the completed templates
  let totalQty = 0;
  let totalLines = 0;
  let chunks = chunkArrayIntoObjects(
    [...startingArr].sort((a, b) => {
      const itemA = a.item.item.toUpperCase(); // convert to uppercase to ensure case-insensitive sorting
      const itemB = b.item.item.toUpperCase();

      if (itemA < itemB) {
        return -1; // itemA comes first
      }
      if (itemA > itemB) {
        return 1; // itemB comes first
      }
      return 0; // items are equal
    }),
    colSize
  ); //transforms given array into an array of arrays seperated by the given column size

  //generate templates by looping through array chunks
  for (const chunk of chunks) {
    const { col1, col2 } = chunk;
    totalLines += col1.length + col2.length;
    let templateBody = "^FX";

    if (col1.length) {
      let col1QtyString = `^FT0,1125^FB125,22,0,C,0^A0N,30^FD${col1
        .map((obj, index) => {
          //add up col1 qty
          totalQty += obj.palletpackedamount;

          return index === col1.length - 1
            ? `${obj.palletpackedamount}^FS`
            : `${obj.palletpackedamount}\\&`;
        })
        .join("")}`;

      let col1ItemsString = `^FT125,1125^FB300,22,0,L,0^A0N,30^FD${col1
        .map((obj, index) => {
          return index === col1.length - 1
            ? `${obj.item.item}^FS`
            : `${obj.item.item}\\&`;
        })
        .join("")}`;

      templateBody += col1QtyString;
      templateBody += col1ItemsString;
    }

    if (col2.length) {
      let col2QtyString = `^FT425,1125^FB125,22,0,C,0^A0N,30^FD${col2
        .map((obj, index) => {
          //add up col2 qty
          totalQty += obj.palletpackedamount;

          return index === col2.length - 1
            ? `${obj.palletpackedamount}^FS`
            : `${obj.palletpackedamount}\\&`;
        })
        .join("")}`;

      let col2ItemsString = `^FT550,1125^FB300,22,0,L,0^A0N,30^FD${col2
        .map((obj, index) => {
          return index === col2.length - 1
            ? `${obj.item.item}^FS`
            : `${obj.item.item}\\&`;
        })
        .join("")}`;

      templateBody += col2QtyString;
      templateBody += col2ItemsString;
    }

    templateBody += "^FX";

    finalTemplates.push({
      template: templateHeader + templateBody,
    });
  }

  //loop through final templates and print pages
  for (let i = 0; i < finalTemplates.length; i++) {
    const { template } = finalTemplates[i];
    const templateFooter = `^FX
    ^FT0,1150^GB820,1,4,B^FS
    ^FT20,1190^FB300,1,0,L,0^A0N,25^FDPage ${i + 1} of ${
      finalTemplates.length
    }^FS
    ^FT400,1190^FB125,1,0,L,0^A0N,25^FD${totalLines} Lines^FS
    ^FT600,1190^FB125,1,0,L,0^A0N,25^FD${totalQty} Units^FS
    ^FX
    ^PQ1,0,1,Y^XZ`;

    let templateType =
      currentPrinter.printerresolution !== "203dpi"
        ? btoa(scaleZPL(template + templateFooter))
        : btoa(template + templateFooter);

    let printJobUrl = "https://api.printnode.com/printjobs";
    let apiID = process.env.REACT_APP_PRINT_API_ID;
    let body = {
      printerId: currentPrinter.printnodeid,
      title,
      contentType: "raw_base64",
      content: templateType,
      source: "MERN > Pack Process > printContentLabels",
    };

    await axios.post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    });
  }
};
//returns new array split up depending on chunk size
const chunkArrayIntoObjects = (arr, colSize) => {
  let result = [];
  let numChunks = Math.ceil(arr.length / (colSize * 2)); // Each object has two columns with colSize items each

  for (let i = 0; i < numChunks; i++) {
    let start = i * (colSize * 2); // Start index for each chunk
    let middle = Math.min(start + colSize, arr.length); // Middle point to separate col1 and col2
    let end = Math.min(start + colSize * 2, arr.length); // End point for the chunk

    // Create an object with col1 and col2
    result.push({
      col1: arr.slice(start, middle), // First 22 items for col1
      col2: arr.slice(middle, end), // Next 22 items for col2
    });
  }

  return result;
};
