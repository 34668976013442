import { useEffect, useState } from "react";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";
import { printGenericLabel } from "../../../utils/printer-functions/printLabelFunctions";
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import axios from "../../../axios/axios.config";
import Loading from "../../../components/Loading";

const PrintGenericLabels = () => {
  const { openAlert } = useGeneralAlert();
  const { printer, currentSubsidiary } = useSelector((state) => state.user);

  const [loading, setLoading] = useState("");
  const [printerDetails, setPrinterDetails] = useState({});
  const [input, setInput] = useState({
    barcode: "",
    additional: "",
    size: currentSubsidiary === "WGE" ? "75x65" : "4x6",
    qty: 1,
  });

  useEffect(() => {
    if (printer) {
      getPrinterDetails();
    }
  }, [printer]);

  const getPrinterDetails = async () => {
    try {
      setLoading("Getting Printer Details...");
      //finds printer details by printername in mongo
      const response = await axios.get(`printers/${printer}`);

      setPrinterDetails(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Printer: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleChanges = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Printing...");
    try {
      await printGenericLabel({
        printer: printerDetails.printnodeid,
        size: input.size,
        qty: input.qty,
        barcode: input.barcode,
        additional: input.additional,
      });
      openAlert({
        type: "success",
        message: `Successfully Printed Label${
          parseInt(input.qty) > 1 ? "s" : ""
        }`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Print: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (loading) {
    <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      {!printer || !printerDetails ? (
        <FlexWrapper height="80vh" justifyContent="center" alignItems="center">
          <Typography variant="h5" textAlign="center" fontWeight="bold">
            No printer selected. <br /> Please update settings in user profile.
          </Typography>
        </FlexWrapper>
      ) : (
        <>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Print Generic Label
          </Typography>
          <Stack spacing={1} component="form" onSubmit={handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              required={currentSubsidiary !== "WGE"}
              label="BARCODE TEXT"
              name="barcode"
              value={input.barcode}
              onChange={handleChanges}
            />
            <TextField
              fullWidth
              label="ADDITIONAL TEXT"
              name="additional"
              value={input.additional}
              onChange={handleChanges}
            />
            <FormControl fullWidth required>
              <InputLabel id="size-select-label">Size</InputLabel>
              <Select
                labelId="size-select-label"
                id="size-select"
                label="Size"
                name="size"
                value={input.size}
                onChange={handleChanges}
              >
                {currentSubsidiary !== "WGE" && (
                  <MenuItem value="4x6">4x6</MenuItem>
                )}
                {currentSubsidiary !== "WGE" && (
                  <MenuItem value="4x2">4x2</MenuItem>
                )}
                {currentSubsidiary === "WGE" && (
                  <MenuItem value="75x65">75x65</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              required
              type="number"
              inputProps={{ min: 1 }}
              label="PRINT QTY"
              value={input.qty}
              name="qty"
              onChange={handleChanges}
            />
            <TextField
              disabled
              fullWidth
              type="text"
              label="Printer Name"
              value={printerDetails?.printername || ""}
            />
            <Button fullWidth variant="contained" type="submit">
              Print
            </Button>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default PrintGenericLabels;
