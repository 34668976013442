import { CssBaseline } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Loaders
import { portalLoader } from "./loaders/portal/portalLoaders";
import { registerLoader } from "./loaders/auth/authLoaders";
import { sessionLoader } from "./loaders/session/sessionLoaders";
import { adminLoader } from "./loaders/admin/adminLoader";
import { bugReportsAdminLoader } from "./loaders/admin/bug-reports/bugReportsAdminLoader";
import { logsLoader } from "./loaders/admin/logs/logsLoader";
import { userLoader } from "./loaders/admin/users/userLoader";
import { printerLoader } from "./loaders/admin/printers/printerLoader";
import { integrationErrorsAdminLoader } from "./loaders/admin/integration-erros/integrationErrorsLoader";
import { userProfileLoader } from "./loaders/user-profile/userProfileLoader";
import { laborClaimProductLoader } from "./loaders/product/productLoader";
import { userProfilePrintersLoader } from "./loaders/user-profile/printers/userProfilePrintersLoader";
import { labelAndBoxRequestFormViewLoader } from "./loaders/customer-service/customerServiceLoaders";
import {
  laborClaimNingboPageLoader,
  laborClaimViewLoader,
} from "./loaders/multi-page/laborClaimViewLoader";
import { pickOrderResultsPageLoader } from "./loaders/multi-page/pickOrderLoader";
import { inventoryAdminPageLoader } from "./loaders/inventory/inventoryLoader";
import { feedbackLoader } from "./loaders/feedback/feedbackLoaders";
import {
  receiveTransactionResultsLoader,
  viewReceivingTransactionsLoader,
} from "./loaders/receiving/receivingLoaders";
import {
  addCycleCountPageLoader,
  createCycleCountsPageLoader,
  cycleCountsPageLoader,
  enterCycleCountsIndividualJobLoader,
  reviewCycleCountsCountDetailsPageLoader,
} from "./loaders/cycle-count/cycleCountLoaders";
import {
  equipmentChecklistLoader,
  equipmentChecklistMainPageLoader,
  equipmentReportsLoader,
  equipmentSingleReportLoader,
} from "./loaders/equipment/equipmentLoader";
import { laborClaimEditAttachmentsLoader } from "./loaders/returns/returnsLoader";
import {
  warrantyClaimAddLoader,
  warrantyClaimViewLoader,
} from "./loaders/multi-page/warrantyClaimLoader";
import {
  driverCheckinAdminLoader,
  driverCheckinLoader,
} from "./loaders/checkins/checkinLoaders";
import { pickShortsPageLoader } from "./loaders/multi-page/pickShortsLoader";
import {
  loadOrders,
  loadSingleOrder,
} from "./loaders/multi-page/packProcessViewOrdersLoader";
import { updatePackResultsPageLoader } from "./loaders/pack-process/packProcessLoaders";

//ACTIONS
import { equipmentCheckListFormAction } from "./actions/equipment/equipmentActions";
import { driverCheckInAction } from "./actions/checkins/driverCheckInsAction";
import { bugReportAction } from "./actions/feedback/bugReportAction";

import ToteManager from "./pages/ToteManager";
import ItemDisposition from "./pages/ItemDisposition";
import Confirmation from "./pages/general/Confirmation";
import InfoSelect from "./pages/InfoSelect";
import ToteSearch from "./pages/ToteSearch";
import ToteResult from "./pages/ToteResult";
import PrintItemLabel from "./pages/reverse-logistics/PrintItemLabel";
import PrintItemLabels from "./pages/warehouse/PrintItemLabels";
import ValidateTote from "./pages/ValidateTote";
import CommunicationDashboard from "./pages/sales-pages/CommunicationDashboard";
import ReplenishmentAnalysis from "./pages/procurement/ReplenishmentAnalysis";
import Overstock from "./pages/procurement/Overstock";
import Understock from "./pages/procurement/Understock";
import AllItems from "./pages/procurement/AllItems";
import UnderstockAnalysis from "./pages/procurement/UnderstockAnalysis";
import PackProcess from "./pages/warehouse/PackProcess";
import ProposalPage from "./pages/sales-pages/ProposalPage";
import DealSheetPage from "./pages/sales-pages/DealSheetPage";
import Receiving from "./pages/procurement/Receiving";
import LostSales from "./pages/procurement/LostSales";
import ConsecutiveAlerts from "./components/ConsecutiveAlerts";
import UpdatePackOrder from "./pages/warehouse/UpdatePackOrder";
import PackProcessDetails from "./pages/warehouse/PackProcessDetails";
import Portal from "./pages/general/Portal";
import ErrorPage from "./pages/error/ErrorPage";
import NotFound from "./pages/error/NotFound";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import PortalMenu from "./pages/multi-page/PortalMenu";
import GeneralAlert from "./components/GeneralAlert";
import AuthLayout from "./pages/auth/AuthLayout";
import RankingProcess from "./pages/procurement/RankingProcess";
import Printers from "./pages/admin/Printers";

// ADMIN
import IntegrationErrors from "./pages/admin/IntegrationErrors";
import Admin from "./pages/admin/Admin";
import Users from "./pages/admin/Users";
import Logs from "./pages/admin/Logs";
import CustomLinks from "./pages/admin/CustomLinks";
import BugReportsAdmin from "./pages/admin/BugReportsAdmin";

// MULTI PAGE
import LaborClaimLayout from "./pages/multi-page/LaborClaimLayout";
import LabelsAndBoxRequestCustomerServiceLayout from "./pages/multi-page/LabelsAndBoxRequestLayout";
import LabelsAndBoxRequestFormAdd from "./pages/multi-page/LabelsAndBoxRequestFormAdd";
import LabelsAndBoxRequestMultiPage from "./pages/multi-page/LabelsAndBoxRequestMultiPage";
import LaborClaimPageMulti from "./pages/multi-page/LaborClaimPageMulti";
import LaborClaimAddMultiPage from "./pages/multi-page/LaborClaimAddMultiPage";
import LaborClaimViewMultiPage from "./pages/multi-page/LaborClaimViewMultiPage";
import WarrantyFormPageMulti from "./pages/multi-page/warranty-form/WarrantyFormPageMulti";
import WarrantyFormLayout from "./pages/multi-page/warranty-form/WarrantyFormLayout";
import WarrantyFormAddMulti from "./pages/multi-page/warranty-form/WarrantyFormAddMulti";
import WarrantyFormViewMulti from "./pages/multi-page/warranty-form/WarrantyFormViewMulti";
import PickOrderResultsPage from "./pages/multi-page/pick-order/PickOrderResultsPage";
import InventoryMovePage from "./pages/multi-page/inventory-move/InventoryMovePage";
import EquipmentChecklist from "./pages/multi-page/equipment/EquipmentChecklist";
import EquipmentChecklistLayout from "./pages/multi-page/equipment/EquipmentChecklistLayout";
import PackProcessViewOrdersLayout from "./pages/multi-page/pack-process-admin/PackProcessViewOrdersLayout";
import PackProcessViewOrdersPage from "./pages/multi-page/pack-process-admin/PackProcessViewOrdersPage";
import PackProcessViewSingleOrderPage from "./pages/multi-page/pack-process-admin/PackProcessViewSingleOrderPage";
import PickViewOrdersLayout from "./pages/multi-page/pick-order-admin/PickViewOrdersLayout";
import PickViewOrdersPage from "./pages/multi-page/pick-order-admin/PickViewOrdersPage";
import GeneralPDFViewerV2 from "./components/PDF/GeneralPDFViewerV2";
import PrintGenericLabels from "./pages/multi-page/labels/PrintGenericLabels";

//WAREHOUSE
import OrderProcessing from "./pages/warehouse/orders/order-processing/OrderProcessing";
import SpecialOrders from "./pages/warehouse/orders/special-orders/SpecialOrders";
import ReceiveTransactionLayout from "./pages/warehouse/receiving/receive-transaction/ReceiveTransactionLayout";
import ReceiveTransactionSearchPage from "./pages/warehouse/receiving/receive-transaction/ReceiveTransactionSearchPage";
import InventoryAdminPageLayout from "./pages/warehouse/inventory/InventoryAdminPageLayout";
import InventoryAdminPage from "./pages/warehouse/inventory/InventoryAdminPage";
import ViewReceivedTransactionsLayout from "./pages/warehouse/receiving/receive-transaction-admin/ViewReceivedTransactionsLayout";
import ViewReceivedTransactionsPage from "./pages/warehouse/receiving/receive-transaction-admin/ViewReceivedTransactionsPage";
import CycleCountsLayout from "./pages/warehouse/cycle-counts/CycleCountsLayout";
import CycleCountsPage from "./pages/warehouse/cycle-counts/CycleCountsPage";
import AddCycleCountPage from "./pages/warehouse/cycle-counts/AddCycleCountPage";
import PickDiscrepanciesLayout from "./pages/multi-page/inventory-move/pick-discrepancies/PickDiscrepanciesLayout";
import CreateCycleCounts from "./pages/warehouse/cycle-counts/create-cycle-counts/CreateCycleCounts";
import EnterCycleCountsLayout from "./pages/warehouse/cycle-counts/enter-cycle-counts/EnterCycleCountsLayout";
import EnterCycleCountsPage from "./pages/warehouse/cycle-counts/enter-cycle-counts/EnterCycleCountsPage";
import EnterCycleCountsIndividualJobPage from "./pages/warehouse/cycle-counts/enter-cycle-counts/EnterCycleCountsIndividualJobPage";
import ReviewCycleCountsLayout from "./pages/warehouse/cycle-counts/review-cycle-counts/ReviewCycleCountsLayout";
import ReviewCycleCountsPage from "./pages/warehouse/cycle-counts/review-cycle-counts/ReviewCycleCountsPage";

// RETURNS
import LaborCLaimReturnsPage from "./pages/returns/LaborClaimReturnsPage";
import LaborClaimReturnsFormView from "./pages/returns/forms/LaborClaimReturnsFormView";
import LaborClaimProductPage from "./pages/product/LaborClaimProductPage";
import LaborClaimProductFormView from "./pages/product/forms/LaborClaimProductFormView";
import LaborClaimReturnsFormProcessedView from "./pages/returns/forms/LaborClaimReturnsFormProcessedView";
import LaborClaimReturnsNewView from "./pages/returns/LaborClaimReturnsNewView";
import LaborClaimReturnsInProgressView from "./pages/returns/LaborClaimReturnsInProgressView";
import LaborClaimReturnsProcessedView from "./pages/returns/LaborClaimReturnsProcessedView";

//CUSTOMER SERVICE
import LabelsAndBoxRequestCustomerServiceFormView from "./pages/customer-service/forms/LabelsAndBoxRequestCustomerServiceFormView";
import LabelsAndBoxRequestCustomerServicePage from "./pages/customer-service/LabelsAndBoxRequestCustomerServicePage";
import LabelsAndBoxRequestCustomerServiceFormAdd from "./pages/customer-service/forms/LabelsAndBoxRequestCustomerServiceFormAdd";

//REVERSE LOGISTICS
import LabelsAndBoxRequestReverseLogisticsPage from "./pages/reverse-logistics/LabelsAndBoxRequestReverseLogisticsPage";
import LabelsAndBoxRequestReverseLogisticsFormAdd from "./pages/reverse-logistics/forms/LabelsAndBoxRequestReverseLogisticsFormAdd";
import LabelsAndBoxRequestReverseLogisticsFormView from "./pages/reverse-logistics/forms/LabelsAndBoxRequestReverseLogisticsFormView";
import ToteSearchV2 from "./pages/reverse-logistics/tote-search/ToteSearchV2";

//SALES
import LabelsAndBoxRequestFormView from "./pages/multi-page/LabelsAndBoxrequestFormView";
import LaborClaimEditAttachments from "./pages/returns/LaborClaimEditAttachments";
import PackProcessLayout from "./pages/multi-page/pack-process/PackProcessLayout";
import PackProcessSearchPage from "./pages/multi-page/pack-process/PackProcessSearchPage";
import PickOrderSearchPageMulti from "./pages/multi-page/pick-order/PickOrderSearchPageMulti";
import PickOrderLayout from "./pages/multi-page/pick-order/PickOrderLayout";
import SingleEquipmentChecklistPage from "./pages/multi-page/equipment/SingleEquipmentChecklistPage";

//CHECKLIST
import ChecklistLayout from "./pages/checklist/ChecklistLayout";
import ChecklistMenu from "./pages/checklist/ChecklistMenu";
import DriverCheckin from "./pages/checklist/DriverCheckin";
import EquipmentReportsLayout from "./pages/multi-page/equipment/EquipmentReportsLayout";
import EquipmentReportsPage from "./pages/multi-page/equipment/EquipmentReportsPage";
import SingleEquipmentReport from "./pages/multi-page/equipment/SingleEquipmentReport";
import PickDiscrepanciesPage from "./pages/multi-page/inventory-move/PickDiscrepanciesPage";
import CheckListAdmin from "./pages/checklist/CheckListAdmin";

// PROFILE SETTINGS PAGES
import ProfileLayout from "./pages/profile/ProfileLayout";
import ProfileGeneral from "./pages/profile/ProfileGeneral";
import ProfilePrinters from "./pages/profile/ProfilePrinters";
import ProfileLanguagePage from "./pages/profile/ProfileLanguagePage";
import ReceiveTransactionResultPage from "./pages/warehouse/receiving/receive-transaction/ReceiveTransactionResultPage";

//FEEDBACK PAGES
import BugReports from "./pages/multi-page/feedback-portal/BugReports";
import FeedbackLayout from "./pages/multi-page/feedback-portal/FeedbackLayout";

//NINGBO PAGES
import LaborClaimNingboPage from "./pages/ningbo/labor-claim/LaborClaimNingboPage";
import LaborClaimNingboLayout from "./pages/ningbo/labor-claim/LaborClaimNingboLayout";
import LaborClaimProcessedViewNingbo from "./pages/ningbo/labor-claim/LaborClaimProcessedViewNingbo";
import UpdatePackLayout from "./pages/warehouse/pack-process/update-pack/UpdatePackLayout";
import UpdatePackSearch from "./pages/warehouse/pack-process/update-pack/UpdatePackSearch";
import UpdatePackResultPage from "./pages/warehouse/pack-process/update-pack/UpdatePackResultPage";
import PackProcessResultsPage from "./pages/multi-page/pack-process/PackProcessResultsPage";
import ReviewCycleCountsCountDetailsPage from "./pages/warehouse/cycle-counts/review-cycle-counts/ReviewCycleCountsCountDetailsPage";

const router = createBrowserRouter([
  {
    path: "/check-in",
    element: <ChecklistLayout />,
    children: [
      {
        path: "drivers",
        element: <DriverCheckin />,
        action: driverCheckInAction,
        loader: driverCheckinLoader,
      },
      {
        path: "view-all",
        element: <CheckListAdmin />,
        loader: driverCheckinAdminLoader,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
        loader: registerLoader,
      },
    ],
  },
  {
    path: "/portal",
    element: <Portal />,
    errorElement: <ErrorPage />,
    loader: portalLoader,
    children: [
      {
        path: "warehouse",
        element: <PortalMenu />,
        children: [
          { path: "order-processing", element: <OrderProcessing /> },
          { path: "special-orders", element: <SpecialOrders /> },
          { path: "print-labels", element: <PrintItemLabels /> },
          { path: "print-generic-labels", element: <PrintGenericLabels /> },
          {
            path: "pack-process-v2",
            element: <PackProcessLayout />,
            children: [
              { index: true, element: <PackProcessSearchPage /> },
              {
                path: ":orderid",
                element: <PackProcessResultsPage />,
              },
            ],
          },
          // {
          //   path: "pack-process",
          //   element: <PackProcess />,
          // },
          {
            path: "pack-process/details",
            element: <PackProcessDetails />,
          },
          {
            path: "update-pack-order",
            // element: <UpdatePackOrder />,
            element: <UpdatePackLayout />,
            children: [
              { index: true, element: <UpdatePackSearch /> },
              {
                path: ":orderid",
                element: <UpdatePackResultPage />,
                loader: updatePackResultsPageLoader,
              },
            ],
          },
          // {
          //   path: "new-pack-process",
          //   element: <PackProcessLayout />,
          //   children: [
          //     { index: true, element: <PackProcessSearchPage /> },
          //     {
          //       path: ":orderid",
          //       element: <PackProcessResultsPage />,
          //       loader: packProcessResultsPageLoader,
          //     },
          //   ],
          // },
          {
            path: "pack-process-orders",
            element: <PackProcessViewOrdersLayout />,
            loader: loadOrders,
            children: [
              {
                index: true,
                element: <PackProcessViewOrdersPage />,
              },
              {
                path: ":orderid",
                loader: loadSingleOrder,
                element: <PackProcessViewSingleOrderPage />,
              },
            ],
          },
          {
            path: "pick-order",
            element: <PickOrderLayout />,
            children: [
              { index: true, element: <PickOrderSearchPageMulti /> },
              {
                path: "order/:orderid",
                element: <PickOrderResultsPage />,
                loader: pickOrderResultsPageLoader,
              },
            ],
          },
          {
            path: "pick-view-orders",
            element: <PickViewOrdersLayout />,
            children: [{ index: true, element: <PickViewOrdersPage /> }],
          },
          {
            path: "inventory-move",
            element: <InventoryMovePage />,
            children: [{ index: true, element: <PickOrderSearchPageMulti /> }],
          },
          {
            path: "view-inventory-moves",
            element: <InventoryAdminPageLayout />,
            children: [
              {
                index: true,
                element: <InventoryAdminPage />,
                loader: inventoryAdminPageLoader,
              },
            ],
          },
          {
            path: "receive-transaction",
            element: <ReceiveTransactionLayout />,
            children: [
              {
                index: true,
                element: <ReceiveTransactionSearchPage />,
              },
              {
                path: ":transaction",
                element: <ReceiveTransactionResultPage />,
                loader: receiveTransactionResultsLoader,
              },
            ],
          },
          {
            path: "pick-discrepancies-cycle-counts",
            element: <CycleCountsLayout />,
            children: [
              {
                index: true,
                loader: cycleCountsPageLoader,
                element: <CycleCountsPage />,
              },
              {
                loader: addCycleCountPageLoader,
                path: "add-cycle-count/:id",
                element: <AddCycleCountPage />,
              },
            ],
          },
          {
            path: "view-received-transactions",
            loader: viewReceivingTransactionsLoader,
            element: <ViewReceivedTransactionsLayout />,
            children: [
              {
                index: true,
                element: <ViewReceivedTransactionsPage />,
              },
            ],
          },
          {
            path: "pick-discrepancies",
            element: <PickDiscrepanciesLayout />,
            children: [
              {
                index: true,
                loader: pickShortsPageLoader,
                element: <PickDiscrepanciesPage />,
              },
            ],
          },
          {
            path: "create-cycle-counts",
            element: <CreateCycleCounts />,
            loader: createCycleCountsPageLoader,
          },
          {
            path: "enter-cycle-counts",
            element: <EnterCycleCountsLayout />,
            children: [
              { index: true, element: <EnterCycleCountsPage /> },
              {
                path: ":jobid",
                element: <EnterCycleCountsIndividualJobPage />,
                loader: enterCycleCountsIndividualJobLoader,
              },
            ],
          },
          {
            path: "review-cycle-counts",
            element: <ReviewCycleCountsLayout />,
            children: [
              {
                index: true,
                element: <ReviewCycleCountsPage />,
              },
              {
                path: ":jobid",
                element: <ReviewCycleCountsCountDetailsPage />,
                loader: reviewCycleCountsCountDetailsPageLoader,
              },
            ],
          },
          {
            path: "equipment-checklist",
            element: <EquipmentChecklistLayout />,
            children: [
              {
                index: true,
                element: <EquipmentChecklist />,
                loader: equipmentChecklistMainPageLoader,
              },
              {
                path: ":equipmentid",
                element: <SingleEquipmentChecklistPage />,
                loader: equipmentChecklistLoader,
                action: equipmentCheckListFormAction,
              },
            ],
          },
          {
            path: "equipment-reports",
            element: <EquipmentReportsLayout />,
            loader: equipmentReportsLoader,
            children: [
              {
                index: true,
                element: <EquipmentReportsPage />,
              },
              {
                path: ":equipmentid",
                element: <SingleEquipmentReport />,
                loader: equipmentSingleReportLoader,
              },
            ],
          },
        ],
      },
      {
        path: "sales",
        element: <PortalMenu />,
        children: [
          {
            path: "activity-dashboard",
            element: <CommunicationDashboard />,
          },
          {
            path: "forms/labels-box-requests",
            element: <LabelsAndBoxRequestCustomerServiceLayout />,
            children: [
              {
                index: true,
                element: <LabelsAndBoxRequestMultiPage />,
              },
              {
                path: "add",
                element: <LabelsAndBoxRequestFormAdd />,
              },
              {
                path: "form/:formid",
                element: <LabelsAndBoxRequestFormView />,
                loader: labelAndBoxRequestFormViewLoader,
              },
            ],
          },
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborClaimPageMulti /> },
              { path: "add", element: <LaborClaimAddMultiPage /> },
              {
                path: "form/:formid",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
            ],
          },
          {
            path: "forms/proposal",
            element: <ProposalPage />,
          },
          {
            path: "forms/deal-sheet",
            element: <DealSheetPage />,
          },
        ],
      },
      {
        path: "procurement",
        element: <PortalMenu />,
        children: [
          {
            path: "replenishment-analysis",
            element: <ReplenishmentAnalysis />,
          },
          {
            path: "understock-analysis",
            element: <UnderstockAnalysis />,
          },
          {
            path: "overstock-analysis",
            element: <Overstock />,
          },
          {
            path: "receiving",
            element: <Receiving />,
          },
          {
            path: "lost-sales",
            element: <LostSales />,
          },
          {
            path: "all-items",
            element: <AllItems />,
          },
          {
            path: "ranking-process",
            element: <RankingProcess />,
          },
        ],
      },
      {
        path: "customer",
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labels-box-requests",
            element: <LabelsAndBoxRequestCustomerServiceLayout />,
            children: [
              {
                index: true,
                element: <LabelsAndBoxRequestMultiPage />,
              },
              {
                path: "add",
                element: <LabelsAndBoxRequestFormAdd />,
              },
              {
                path: "form/:formid",
                element: <LabelsAndBoxRequestFormView />,
                loader: labelAndBoxRequestFormViewLoader,
              },
            ],
          },
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborClaimPageMulti /> },
              { path: "add", element: <LaborClaimAddMultiPage /> },
              {
                path: "form/:formid",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
            ],
          },
          {
            path: "forms/warranty",
            element: <WarrantyFormLayout />,
            children: [
              { index: true, element: <WarrantyFormPageMulti /> },
              {
                path: "add",
                element: <WarrantyFormAddMulti />,
                loader: warrantyClaimAddLoader,
              },
              {
                path: "form/:formid",
                element: <WarrantyFormViewMulti />,
                loader: warrantyClaimViewLoader,
              },
            ],
          },
        ],
      },
      {
        path: "sales-rep",
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labels-box-requests",
            element: <LabelsAndBoxRequestCustomerServiceLayout />,
            children: [
              {
                index: true,
                element: <LabelsAndBoxRequestMultiPage />,
              },
              {
                path: "add",
                element: <LabelsAndBoxRequestFormAdd />,
              },
              {
                path: "form/:formid",
                element: <LabelsAndBoxRequestFormView />,
                loader: labelAndBoxRequestFormViewLoader,
              },
            ],
          },
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborClaimPageMulti /> },
              { path: "add", element: <LaborClaimAddMultiPage /> },
              {
                path: "form/:formid",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
            ],
          },
        ],
      },
      {
        path: "returns",
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborCLaimReturnsPage /> },
              { path: "add", element: <LaborClaimAddMultiPage /> },
              {
                path: "form/:formid/in-progress",
                element: <LaborClaimReturnsInProgressView />,
                loader: laborClaimViewLoader,
              },
              {
                path: "form/:formid/new",
                element: <LaborClaimReturnsNewView />,
                loader: laborClaimViewLoader,
              },
              {
                path: "form/:formid/edit/attachments",
                element: <LaborClaimEditAttachments />,
                loader: laborClaimEditAttachmentsLoader,
              },
              {
                path: "form/:formid/processed",
                element: <LaborClaimReturnsProcessedView />,
                loader: laborClaimViewLoader,
              },
              {
                path: "form/:formid/complete",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
            ],
          },
          {
            path: "forms/warranty",
            element: <WarrantyFormLayout />,
            children: [
              { index: true, element: <WarrantyFormPageMulti /> },
              {
                path: "add",
                element: <WarrantyFormAddMulti />,
                loader: warrantyClaimAddLoader,
              },
              {
                path: "form/:formid",
                element: <WarrantyFormViewMulti />,
                loader: warrantyClaimViewLoader,
              },
            ],
          },
        ],
      },
      {
        path: "reverse-logistics",
        element: <PortalMenu />,
        children: [
          { path: "tote-manager", element: <ToteManager /> },
          {
            path: "item-disposition",
            element: <ItemDisposition />,
          },
          {
            path: "item-disposition",
            element: <ItemDisposition />,
          },
          {
            path: "tote-search",
            element: <ToteSearch />,
            // loader: toteSearchMainPageLoader,
          },
          {
            path: "tote-manager/:toteid",
            element: <ToteResult />,
          },
          {
            path: "tote-manager/:toteid/validate",
            element: <ValidateTote />,
          },
          {
            path: "print-labels",
            element: <PrintItemLabel />,
          },
          { path: "print-generic-labels", element: <PrintGenericLabels /> },
          // {
          //   path: "pack-process",
          //   element: <PackProcess />,
          // },
          {
            path: "pack-process-v2",
            element: <PackProcessLayout />,
            children: [
              { index: true, element: <PackProcessSearchPage /> },
              {
                path: ":orderid",
                element: <PackProcessResultsPage />,
              },
            ],
          },
          {
            path: "pack-process-orders",
            element: <PackProcessViewOrdersLayout />,
            loader: loadOrders,
            children: [
              {
                index: true,
                element: <PackProcessViewOrdersPage />,
              },
              {
                path: ":orderid",
                loader: loadSingleOrder,
                element: <PackProcessViewSingleOrderPage />,
              },
            ],
          },
          {
            path: "forms/labels-box-requests",
            element: <LabelsAndBoxRequestCustomerServiceLayout />,
            children: [
              {
                index: true,
                element: <LabelsAndBoxRequestReverseLogisticsPage />,
              },
              {
                path: "add",
                element: <LabelsAndBoxRequestReverseLogisticsFormAdd />,
              },
              {
                path: "form/:formid",
                element: <LabelsAndBoxRequestReverseLogisticsFormView />,
                loader: labelAndBoxRequestFormViewLoader,
              },
            ],
          },
          {
            path: "equipment-checklist",
            element: <EquipmentChecklistLayout />,
            children: [
              {
                index: true,
                element: <EquipmentChecklist />,
                loader: equipmentChecklistMainPageLoader,
              },
              {
                path: ":equipmentid",
                element: <SingleEquipmentChecklistPage />,
                loader: equipmentChecklistLoader,
                action: equipmentCheckListFormAction,
              },
            ],
          },
          {
            path: "receive-transaction",
            element: <ReceiveTransactionLayout />,
            children: [
              {
                index: true,
                element: <ReceiveTransactionSearchPage />,
              },
              {
                path: ":transaction",
                element: <ReceiveTransactionResultPage />,
                loader: receiveTransactionResultsLoader,
              },
            ],
          },
          {
            path: "view-received-transactions",
            loader: viewReceivingTransactionsLoader,
            element: <ViewReceivedTransactionsLayout />,
            children: [
              {
                index: true,
                element: <ViewReceivedTransactionsPage />,
              },
            ],
          },
          {
            path: "equipment-reports",
            element: <EquipmentReportsLayout />,
            loader: equipmentReportsLoader,
            children: [
              {
                index: true,
                element: <EquipmentReportsPage />,
              },
              {
                path: ":equipmentid",
                element: <SingleEquipmentReport />,
                loader: equipmentSingleReportLoader,
              },
            ],
          },
        ],
      },
      {
        path: "customer-service", //renders Menu
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labels-box-requests",
            element: <LabelsAndBoxRequestCustomerServiceLayout />,
            children: [
              {
                index: true,
                element: <LabelsAndBoxRequestCustomerServicePage />,
              },
              {
                path: "add",
                element: <LabelsAndBoxRequestCustomerServiceFormAdd />,
              },
              {
                path: "form/:formid",
                element: <LabelsAndBoxRequestCustomerServiceFormView />,
                loader: labelAndBoxRequestFormViewLoader,
              },
            ],
          },
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborClaimPageMulti /> },
              { path: "add", element: <LaborClaimAddMultiPage /> },
              {
                path: "form/:formid",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
            ],
          },
        ],
      },
      {
        path: "product",
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labor-claim",
            element: <LaborClaimLayout />,
            children: [
              { index: true, element: <LaborClaimProductPage /> },
              {
                path: "form/:formid",
                element: <LaborClaimProductFormView />,
                loader: laborClaimProductLoader,
              },
              {
                path: "form/:formid/complete",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
              {
                path: "form/:formid/edit/attachments",
                element: <LaborClaimEditAttachments />,
                loader: laborClaimEditAttachmentsLoader,
              },
            ],
          },
        ],
      },
      {
        path: "ningbo",
        element: <PortalMenu />,
        children: [
          {
            path: "forms/labor-claim",
            element: <LaborClaimNingboLayout />,
            children: [
              {
                index: true,
                element: <LaborClaimNingboPage />,
                loader: laborClaimNingboPageLoader,
              },
              {
                path: ":formid/processed",
                element: <LaborClaimProcessedViewNingbo />,
                loader: laborClaimViewLoader,
              },
              {
                path: ":formid/complete",
                element: <LaborClaimViewMultiPage />,
                loader: laborClaimViewLoader,
              },
              {
                path: "form/:formid/edit/attachments",
                element: <LaborClaimEditAttachments />,
                loader: laborClaimEditAttachmentsLoader,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/session",
    errorElement: <ErrorPage />,
    loader: sessionLoader,
    element: <InfoSelect />,
  },
  {
    path: "/confirmation",
    errorElement: <ErrorPage />,
    element: <Confirmation />,
  },
  {
    path: "/admin",
    errorElement: <ErrorPage />,
    loader: adminLoader,
    element: <Admin />,
    children: [
      { index: true, element: <Users />, loader: userLoader },
      { path: "logs", element: <Logs />, loader: logsLoader },
      { path: "printers", element: <Printers />, loader: printerLoader },
      { path: "custom-links", element: <CustomLinks /> },
      {
        path: "integration-errors",
        element: <IntegrationErrors />,
        loader: integrationErrorsAdminLoader,
      },
      {
        path: "bug-reports",
        element: <BugReportsAdmin />,
        loader: bugReportsAdminLoader,
      },
    ],
  },
  {
    path: "/user-profile",
    errorElement: <ErrorPage />,
    element: <ProfileLayout />,
    loader: userProfileLoader,
    children: [
      { index: true, element: <ProfileGeneral /> },
      {
        path: "printers",
        element: <ProfilePrinters />,
        loader: userProfilePrintersLoader,
      },
      {
        path: "language",
        element: <ProfileLanguagePage />,
      },
    ],
  },
  {
    path: "/feedback",
    errorElement: <ErrorPage />,
    element: <FeedbackLayout />,
    loader: feedbackLoader,
    children: [
      { index: true, element: <BugReports />, action: bugReportAction },
    ],
  },
  // { path: "/view-pdf", element: <GeneralPDFViewerV2 /> }, path for creating PDFs
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App = () => {
  return (
    <>
      <CssBaseline />
      <ConsecutiveAlerts />
      <GeneralAlert />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
