import { useState } from "react";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { PersonAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import FlexWrapper from "../../../FlexWrapper";

const StockOrdersAssignPickersModal = ({
  pickers,
  selectedOrders,
  setLoading,
  getOrderdata,
  totalPickQty,
  handleUpdateNetSuiteOrder,
}) => {
  const [selectedPicker, setSelectedPicker] = useState("");

  const { openAlert } = useGeneralAlert();

  const handleAssignPicker = async () => {
    setLoading("Assigning Picker...");

    try {
      await handleUpdateNetSuiteOrder({
        selectedOrders,
        pickerInternalId: selectedPicker,
        pickerAssignedTime: new Date().toLocaleString()?.replace(",", ""),
      });

      //re-fetch order data
      await getOrderdata();
      openAlert({
        type: "success",
        message: "Successfully Assigned Picker",
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Assign Pickers: ${
          error.response?.data || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <GeneralModalV3
      onClose={() => setSelectedPicker("")}
      openComponent={
        <Button
          color="success"
          size="small"
          variant="contained"
          endIcon={<PersonAdd />}
        >
          Assign Picker
        </Button>
      }
    >
      {/* Display total number of selected orders */}
      <Box mt={2}>
        <Typography variant="subtitle1" pl={1}>
          Selected Pick Qty: <strong>{totalPickQty}</strong>
        </Typography>
        <Typography variant="subtitle1" pl={1}>
          {selectedOrders.length > 0
            ? `Selected Orders (${selectedOrders.length}):`
            : ""}
        </Typography>

        {/* Nicely formatted list of selected orders */}
        {selectedOrders.length > 0 && (
          <Box
            mt={1}
            p={1}
            sx={{
              border: "1px solid lightgray",
              borderRadius: "4px",
              maxHeight: "150px",
              overflowY: "auto",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography fontWeight="bold">
              {selectedOrders.map((orderObj) => orderObj["SO #"]).join(", ")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box px={1} py={3}>
        <FormControl disabled={!selectedOrders?.length} fullWidth required>
          <InputLabel id="demo-simple-select-pickers">Picker</InputLabel>
          <Select
            labelId="demo-simple-select-pickers"
            id="demo-simple-select-pickers"
            label="Pickers"
            name="selectedPickers"
            value={selectedPicker}
            onChange={(event) => setSelectedPicker(event.target.value)}
          >
            {pickers.map((picker) => {
              return (
                <MenuItem key={picker.id} value={picker.id}>
                  {picker.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <FlexWrapper p="10px 5px" justifyContent="flex-end">
        <Button
          disabled={!selectedOrders?.length || !Boolean(selectedPicker)}
          variant="contained"
          color="success"
          onClick={handleAssignPicker}
        >
          Assign Picker
        </Button>
      </FlexWrapper>
    </GeneralModalV3>
  );
};
export default StockOrdersAssignPickersModal;
