import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toggleNav } from "../../../redux/features/nav/navBarSlice";
import {
  changeTabValue,
  loadPackDataV2,
} from "../../../redux/features/pack-process-v2/packProcessV2Slice";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  AddBox,
  FilterCenterFocus,
  Info,
  ViewList,
  HourglassBottom,
} from "@mui/icons-material";

import Loading from "../../../components/Loading";
import FlexWrapper from "../../../components/FlexWrapper";
import PackProcessInfoModal from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessInfoModal";
import PackProcessErrorModal from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessErrorModal";
import PackProcessHeader from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessHeader";
import PackProcessItemDisplay from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessItemDisplay";
import PackProcessAllScansView from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessAllScansView";
import PackProcessBoxDetailsModal from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessBoxDetailsModal";
import PackProcessBoxInfoModal from "../../../components/multi-page/pack-process/pack-process-results-page/PackProcessBoxInfoModal";

const PackProcessResultsPage = () => {
  const dispatch = useDispatch();
  const { orderid } = useParams();
  const {
    scans,
    loading,
    errors,
    createdfrom,
    packdetails,
    palletnumber,
    totalitemstopack,
    totalpacked,
    tabValue,
    brand,
    isBoxModalOpen,
    boxDetails,
  } = useSelector((state) => state.packProcessV2);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  //hides nav on page
  useEffect(() => {
    dispatch(toggleNav(true));
    return () => dispatch(toggleNav(false));
  }, []);

  //gets redux data
  useEffect(() => {
    getPackData();
  }, []);

  const getPackData = () => {
    dispatch(loadPackDataV2(orderid));
    setIsInfoModalOpen(true);
  };

  if (loading) {
    return <Loading message={loading} />;
  }
  /* START MODALS */
  if (!loading && isInfoModalOpen) {
    return <PackProcessInfoModal close={setIsInfoModalOpen} />;
  }
  //scan items to box and view box quantities modal
  if (!errors?.length && Boolean(isBoxModalOpen)) {
    return <PackProcessBoxDetailsModal />;
  }
  //Modal to either create a  box or update weight and dimensions for a given box
  if (!errors?.length && Boolean(boxDetails)) {
    return <PackProcessBoxInfoModal />;
  }

  if (errors?.length) {
    return <PackProcessErrorModal />;
  }

  /* END MODALS */

  return (
    <Container maxWidth="md">
      <FlexWrapper justifyContent="center" alignItems="center" gap={1}>
        <Typography textAlign="center" variant="h6" fontSize="18px">
          {createdfrom} - {brand}
        </Typography>
        <IconButton
          disableRipple
          size="small"
          onClick={() => setIsInfoModalOpen(true)}
        >
          <Info color="primary" fontSize="small" />
        </IconButton>
      </FlexWrapper>

      {tabValue !== 3 && <PackProcessHeader />}

      {Boolean(packdetails?.length) && tabValue !== 3 ? (
        <Box
          sx={{
            overflowY: "scroll",
            height: "calc(100vh - 177px)",
            pb: 1,
          }}
        >
          <PackProcessItemDisplay tabValue={tabValue} />
        </Box>
      ) : null}

      {tabValue === 3 && <PackProcessAllScansView />}

      {Boolean(packdetails?.length) ? (
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={tabValue}
            onChange={(event, newValue) => {
              dispatch(changeTabValue(newValue));
            }}
          >
            <BottomNavigationAction
              label={`Pallet ${packdetails[palletnumber]?.palletId}`}
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={packdetails[palletnumber]?.totalitemquantity}
                  color="success"
                >
                  <AddBox />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Remaining"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={totalitemstopack - totalpacked}
                  color="error"
                >
                  <HourglassBottom />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="All items"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={totalitemstopack}
                  color="primary"
                >
                  <ViewList />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="All Actions"
              icon={
                <Badge
                  showZero
                  max={1000}
                  badgeContent={scans.length}
                  color="primary"
                >
                  <FilterCenterFocus />
                </Badge>
              }
            />
          </BottomNavigation>
        </Paper>
      ) : null}
    </Container>
  );
};

export default PackProcessResultsPage;
